import React, { useEffect, useRef, useState } from 'react';
import { Box, Badge, Menu, MantineColor, ComboboxData } from '@mantine/core';

import { RequirementImportance } from '../types';

export const IMPORTANCE_LABELS: Record<RequirementImportance, string> = {
  [RequirementImportance.Major]: 'Major',
  [RequirementImportance.Normal]: 'Normal',
  [RequirementImportance.Minor]: 'Minor',
  [RequirementImportance.Info]: 'Info',
  [RequirementImportance.GradeOnly]: 'Grade Only',
};

export const IMPORTANCE_COLORS: Record<RequirementImportance, MantineColor> = {
  [RequirementImportance.Major]: 'var(--mantine-color-red-9)',
  [RequirementImportance.Normal]: 'var(--salv-dark-6)',
  [RequirementImportance.Minor]: 'var(--salv-dark-4)',
  [RequirementImportance.Info]: 'var(--mantine-color-blue-4)',
  [RequirementImportance.GradeOnly]: 'var(--mantine-color-blue-8)',
};

const IMPORTANCE_OPTIONS: ComboboxData = Object.entries(IMPORTANCE_LABELS).map(
  ([value, label]) => ({
    value,
    label,
  })
);

const BadgeSelector = ({ value, onChange }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
    }
  }, []);

  return (
    <Box ref={containerRef} style={{ width: width }}>
      <Menu position='bottom-start'>
        <Menu.Target>
          <Badge
            variant='outline'
            color={IMPORTANCE_COLORS[value]}
            style={{ fontWeight: 600, cursor: 'pointer' }}
          >
            {IMPORTANCE_LABELS[value]}
          </Badge>
        </Menu.Target>
        <Menu.Dropdown style={{ padding: '8px' }}>
          {IMPORTANCE_OPTIONS.map((option, index) => (
            <Menu.Item
              key={option}
              onClick={() => onChange(option.value)}
              style={{
                padding: 0,
                marginTop: index === 0 ? 0 : '8px', // apply margin only on items after the first
              }}
            >
              <Badge
                variant='outline'
                color={IMPORTANCE_COLORS[option.value]}
                style={{
                  fontWeight: 600,
                  // width: '100%',
                  textAlign: 'left',
                  padding: '8px',
                }}
              >
                {IMPORTANCE_LABELS[option.value]}
              </Badge>
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    </Box>
  );
};

export default BadgeSelector;
