// Set these values for production deployment
export const env = {
  REACT_APP_SERVER_URL: 'https://run.salv.ai', // https://run.salv.ai
  REACT_APP_WS_URL: 'wss://run.salv.ai', // wss://run.salv.ai
  REACT_APP_STYTCH_PROJECT_ENV: 'live',
  REACT_APP_STYTCH_REDIRECT_URL: 'https://chat.salv.ai/authenticate', // https://chat.salv.ai/authenticate
  REACT_APP_STYTCH_PUBLIC_TOKEN:
    'public-token-live-af29d246-5da0-4abd-89f0-e58c9516a25f',
  REACT_APP_INTERVIEW_REDIRECT_URL: 'https://interview.salv.ai',
};
