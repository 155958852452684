// Set these values for local development connecting to a locally running server connected to PROD resources
export const env = {
  HOST: 'localhost',
  REACT_APP_SERVER_URL: 'http://localhost:8000',
  REACT_APP_WS_URL: 'ws://localhost:8000',
  REACT_APP_STYTCH_PROJECT_ENV: 'live',
  REACT_APP_STYTCH_REDIRECT_URL: 'http://localhost:3000/authenticate',
  REACT_APP_STYTCH_PUBLIC_TOKEN:
    'public-token-live-af29d246-5da0-4abd-89f0-e58c9516a25f',
  REACT_APP_INTERVIEW_REDIRECT_URL: 'http://localhost:3000',
};
