import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { RetellWebClient } from 'retell-client-js-sdk';
import { Avatar } from '@mantine/core';
import './RetellWebCallHandler.css';
import axios from 'api/axiosConfig';
import env from 'env';
import { IconMicrophone } from '@tabler/icons-react';

import MicBars from './MicBars'; // Adjust the import path accordingly
import { useIsMobile } from './useIsMobile';
import useAudioStream from './useAudioStream';
// Adjust the import path accordingly

// const AUDIO_SAMPLE_RATE = 24000;

const RetellWebCallHandler: React.FC<{
  accessToken: string | undefined;
  callId: string | null;
  mediaRecorderRef: React.MutableRefObject<MediaRecorder | null>;
  retellWebClientRef: React.MutableRefObject<RetellWebClient | null>;
  startClicked: boolean;
  callEnded: boolean;
  candidateName: string;
  setCallEnded: React.Dispatch<React.SetStateAction<boolean>>;
  voiceName: string;
  showMicTestModal: boolean;
  getMicLevelUpdates: (callback: (level: number) => void) => void;
  handleCloseMicTest: () => void;
  hasMicrophoneAccess: boolean | undefined;
}> = ({
  accessToken,
  callId,
  mediaRecorderRef,
  retellWebClientRef,
  startClicked,
  callEnded,
  setCallEnded,
  candidateName,
  voiceName,
  showMicTestModal,
  getMicLevelUpdates,
  handleCloseMicTest,
  hasMicrophoneAccess,
}) => {
  console.log('voice name', voiceName);
  const voiceLower = voiceName.toLowerCase();
  const isMale =
    voiceLower.includes('george') || voiceLower.includes('william');
  const { startStreamingAudio, stopStreamingAudio } = useAudioStream(
    callId || ''
  );
  const [started, setStarted] = useState(false);
  const [isTalking, setIsTalking] = useState(false); // State to track talking status
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const [isVideoStreaming, setIsVideoStreaming] = useState(false); // New state for video streaming
  const uploadRecordingChunkRef = useRef<(blob: Blob, count: number) => void>();
  const chunkCountRef = useRef<number>(0);
  const [uploadedChunkCount, setUploadedChunkCount] = useState(0);
  const [mediaRecorderStopped, setMediaRecorderStopped] = useState(false);
  const [sentinelFileSet, setSentinelFileSet] = useState(false);

  const [codecError, setCodecError] = useState(false); // seen on firefox
  const [webCamError, setWebCamError] = useState(false); // usually when user turns off camera access

  const micTestStreamRef = useRef<MediaStream | null>(null);
  const micTestAnalyserRef = useRef<AnalyserNode | null>(null);
  console.log('AnalyserNode:', micTestAnalyserRef.current);
  console.log('MediaStream:', micTestStreamRef.current);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const handleLoadedData = () => setIsVideoStreaming(true);
      videoElement.addEventListener('loadeddata', handleLoadedData);
      return () =>
        videoElement.removeEventListener('loadeddata', handleLoadedData);
    }
  }, []);

  const handleStartRecording = useCallback(() => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== 'recording'
    ) {
      mediaRecorderRef.current.start(500);
    }
  }, [mediaRecorderRef]);

  const handleStopRecording = useCallback(() => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === 'recording'
    ) {
      mediaRecorderRef.current.stop();
    }
  }, [mediaRecorderRef]);

  const isMobile = useIsMobile(600);

  useEffect(() => {
    const startCall = () => {
      // Initialize the RetellWebClient and store it in the ref
      const retellWebClient = new RetellWebClient();
      retellWebClient.on('agent_start_talking', () => {
        setIsTalking(true);
      });

      retellWebClient.on('call_ended', () => {
        setCallEnded(true);
      });

      retellWebClient.on('agent_stop_talking', () => {
        setIsTalking(false);
      });

      retellWebClient.on('call_ended', () => {
        try {
          handleStopRecording();
          stopStreamingAudio();
        } catch {
          console.warn('could not stop recording on retell event');
        }
      });

      retellWebClientRef.current = retellWebClient;
      retellWebClient
        .startCall({
          accessToken: accessToken || '',
        })
        .then(() => {
          console.log('web call started');
          setStarted(true);
          handleStartRecording();
          startStreamingAudio();
        })
        .catch((err) => {
          console.error('err', err);
        });
    };
    if (accessToken) {
      startCall();
    }
  }, [
    accessToken,
    handleStartRecording,
    retellWebClientRef,
    setCallEnded,
    handleStopRecording,
    startStreamingAudio,
    stopStreamingAudio,
  ]);

  const finalizeRecording = useCallback(async () => {
    if (!callId) {
      console.error('Call ID is not defined. Cannot upload recording.');
      return;
    }
    try {
      const formData = new FormData();
      formData.append('callId', callId);
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/finalize_web_call_recording`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status === 200) {
        console.log('Recording uploaded successfully:', response.data);
        setSentinelFileSet(true);
      } else {
        console.error('Failed to upload recording:', response.statusText);
      }
    } catch (error) {
      console.error('Error uploading recording:', error);
    }
  }, [callId]);

  useEffect(() => {
    const handleBeforeUnload = async (event) => {
      if (!sentinelFileSet && callId) {
        console.log('Page is closing, finalizing recording...');
        event.preventDefault(); // This prevents the default behavior of the unload.
        event.returnValue = ''; // This triggers the confirmation dialog in some browsers.
        await finalizeRecording(); // Call your finalize recording function.
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [callId, finalizeRecording, sentinelFileSet]);

  const doneUploading = useMemo(() => {
    // Check if all chunks are sent AND the media recording is done
    if (mediaRecorderStopped && chunkCountRef.current === uploadedChunkCount) {
      return true;
    }
    return false;
  }, [uploadedChunkCount, mediaRecorderStopped]);

  const uploadRecordingChunk = useCallback(
    async (data, count) => {
      if (!callId) {
        console.error('Call ID is not defined. Cannot upload recording.');
        return;
      }

      try {
        // TODO: switch to websocket to slighlty reduce overhead if it becomes a problem
        const formData = new FormData();
        formData.append('file', data, `chunk-${count}.mp4`);
        formData.append('callId', callId);
        formData.append('count', count);

        const response = await axios.post(
          `${env.REACT_APP_SERVER_URL}/upload_web_call_recording_chunk`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        if (response.status === 200) {
          console.log('Recording chunk uploaded successfully:', response.data);
          setUploadedChunkCount((prevCount) => prevCount + 1); // no race condition this way
        } else {
          console.error(
            'Failed to upload recording chunk:',
            response.statusText
          );
        }
      } catch (error) {
        console.error('Error uploading recording chunk:', error);
      }
    },
    [callId]
  );

  useEffect(() => {
    uploadRecordingChunkRef.current = uploadRecordingChunk;
  }, [uploadRecordingChunk]);

  useEffect(() => {
    if (doneUploading) {
      console.log('SETTING SENTINEL FILE');
      finalizeRecording();
    }
  }, [doneUploading, finalizeRecording]);

  useEffect(() => {
    const startWebcam = async () => {
      console.log('STARTING WEBCAM');

      if (videoRef.current) {
        videoRef.current.muted = true;
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
          });
          stream.getVideoTracks().forEach((track) => {
            track.onended = () => {
              console.error(
                'A MediaStreamTrack ended due to a capture failure.'
              );
              setWebCamError(true);
            };
          });
          videoRef.current.srcObject = stream as MediaStream;
          // Combine video and audio streams
          const combinedStream = new MediaStream([
            ...stream.getVideoTracks(), // Video tracks from getUserMedia
          ]);

          // Set up MediaRecorder for combined stream
          const mediaRecorder = new MediaRecorder(combinedStream, {
            mimeType: 'video/mp4; codecs="avc1.64001f,mp4a.40.2"', // Explicit H.264 and AAC
            videoBitsPerSecond: 900000,
          });

          if (
            MediaRecorder.isTypeSupported(
              'video/mp4; codecs="avc1.42E01E,mp4a.40.2"'
            )
          ) {
            console.log('fMP4 supported');
          } else {
            setCodecError(true);
            console.error('fMP4 not supported');
          }
          mediaRecorder.ondataavailable = (event: BlobEvent) => {
            if (event.data.size > 0) {
              chunkCountRef.current = chunkCountRef.current + 1;
              uploadRecordingChunkRef.current?.(
                event.data,
                chunkCountRef.current
              );
            }
          };
          mediaRecorder.onerror = (event: Event) => {
            const error = (event as any).error; // Use 'any' to safely access the error property
            if (error) {
              console.error('MediaRecorder error:', error.name, error.message);
            } else {
              console.error(
                'MediaRecorder error occurred, but no detailed information is available.'
              );
            }
          };
          mediaRecorder.onerror = (event: Event) => {
            const error = (event as any).error; // Use 'any' to safely access the error property
            if (error) {
              console.error('MediaRecorder error:', error.name, error.message);
            } else {
              console.error(
                'MediaRecorder error occurred, but no detailed information is available.'
              );
            }
          };
          mediaRecorder.onstop = () => {
            setMediaRecorderStopped(true);
          };
          mediaRecorderRef.current = mediaRecorder;
          setWebCamError(false);
        } catch (err) {
          console.error('Error accessing webcam:', err);
          if (err?.toString().includes('video/mp4')) {
            setCodecError(true);
          } else {
            setWebCamError(true);
          }
        }
      }
    };

    startWebcam();
  }, [mediaRecorderRef]);

  const videoError = webCamError || codecError;

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      {/* Live Webcam Feed */}
      {!callEnded && (
        <>
          <video
            ref={videoRef}
            autoPlay
            playsInline
            muted
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              // display: 'block', //testCallActive ? 'block' : 'none',
              borderRadius: '18px',
              border: '4px solid white',
              transform: 'scaleX(-1)',
              display: videoError ? 'none' : 'block',
            }}
          />
          {hasMicrophoneAccess === false && (
            <h4
              style={{
                position: 'absolute',
                margin: 'auto',
                width: '100%',
                textAlign: 'center',
                color: 'var(--salv-dark-3)',
                // top: '4px',
              }}
            >
              Please enable microphone access
            </h4>
          )}
        </>
      )}
      {(callEnded || videoError) && (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            padding: '30px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '18px',
            border: '4px solid white',
            flexDirection: 'column',
          }}
        >
          <h3 style={{ color: 'var(--salv-dark-2)', textAlign: 'center' }}>
            {codecError
              ? 'Browser not supported. Please try another browser'
              : webCamError
                ? 'For the interview, please enable webcam access in your browser settings.'
                : !sentinelFileSet
                  ? 'Do not close this tab. Saving Results...'
                  : 'Interview Complete. You may now close the tab.'}
          </h3>
          {!codecError && webCamError && isMobile && (
            <>
              <h5 style={{ color: 'var(--salv-dark-2)', textAlign: 'center' }}>
                If you've enabled webcam access and still cannot see your video,
                try the opening the page in a different browser.
              </h5>
              {/* <button
                className='phone-action-button'
                onClick={copyUrlToClipboard}
                style={{ width: '140px' }}
              >
                Copy Link
              </button> */}
            </>
          )}
        </div>
      )}

      {/* Status Overlay */}
      {startClicked && !callEnded && (
        <div
          style={{
            position: 'absolute',
            top: '16px',
            left: '16px',
            padding: '5px 10px',
            background: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            fontSize: '12px',
            borderRadius: '7px',
          }}
        >
          {callEnded
            ? 'Interview Complete'
            : started
              ? 'Connected'
              : 'Connecting...'}
        </div>
      )}

      {/* Controls */}
      {isVideoStreaming && (
        <div
          style={{
            position: 'absolute',
            bottom: '10px', // Positions the button 10px from the bottom
            left: '50%', // Positions the left edge of the div at the center of the container
            transform: 'translateX(-50%)', // Centers the div horizontally
            display: 'flex',
            gap: '10px',
          }}
        ></div>
      )}

      {candidateName && !callEnded && (
        <div
          style={{
            position: 'absolute',
            fontSize: '18px',
            fontWeight: 600,
          }}
          className='candidate-container'
        >
          {candidateName}
        </div>
      )}

      {/* Avatar Overlay */}
      {isVideoStreaming && started && !callEnded && (
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '120px',
            height: '120px',
            background: isTalking ? 'var(--salv-dark-5)' : 'var(--salv-dark-7)',
            borderRadius: '30px',
          }}
          className='avatar-container'
        >
          <div
            style={{
              position: 'absolute',
              background:
                'radial-gradient(circle, var(--salv-dark-6) 20%, transparent 100%)',
              width: '64%',
              height: '64%',
              borderRadius: '30px',
              marginBottom: '10px',
              animation: isTalking ? 'pulse 0.8s infinite ease-in-out' : 'none',
              opacity: 0.8,
            }}
          ></div>
          <Avatar
            // color='dark'
            radius='sm'
            src={
              !isMale
                ? '/imgs/female_interviewer.png'
                : '/imgs/male_interviewer.png'
            }
            style={{
              width: '50%',
              height: '50%',
              borderRadius: '24px',
              marginBottom: '10px',
            }}
          />
          <div
            style={{
              position: 'absolute',
              bottom: '7px',
              fontSize: '10px',
              fontWeight: '500',
            }}
          >
            Morgan
          </div>
        </div>
      )}

      {/* Microphone Test Modal */}
      {showMicTestModal && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
          onClick={(e) => {
            console.log('OUTER CLICKED');
            e.preventDefault();
            e.stopPropagation();
            handleCloseMicTest();
          }}
        >
          <div
            className='mic-test-modal'
            style={{
              background: 'var(--salv-dark-5)',
              padding: '24px',
              borderRadius: '16px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '300px',
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <IconMicrophone
              size={40}
              color='white'
              style={{ marginBottom: '4px', marginTop: '12px' }}
            />
            <h3 style={{ color: 'white', marginBottom: '16px' }}>
              Microphone Test
            </h3>
            <MicBars getMicLevelUpdates={getMicLevelUpdates} />

            <p
              style={{
                color: 'white',
                marginBottom: '16px',
                textAlign: 'center',
              }}
            >
              Speak into your microphone to test the audio input
            </p>
            <button
              onClick={(e) => {
                console.log('CLOSE CLICKED');
                e.preventDefault();
                e.stopPropagation();
                handleCloseMicTest();
              }}
              style={{
                background: 'white',
                color: 'var(--salv-dark-5)',
                border: 'none',
                padding: '8px 16px',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RetellWebCallHandler;
