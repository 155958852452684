import React, { useCallback } from 'react';
import {
  ActionIcon,
  Switch,
  Group,
  Input,
  Textarea,
  Table,
  Tooltip,
  Button,
  Flex,
} from '@mantine/core';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import {
  IconTrash,
  IconGripVertical,
  IconHelp,
  IconPlus,
} from '@tabler/icons-react';

import './ScreeningQuestionsStep.css';

import { RequirementImportance, CandidateRequirement } from '../types';
import BadgeSelector from './BadgeSelector';

enum EditableRequirementField {
  Grading = 'grading',
  Question = 'question',
}

export type RequriementEditState = {
  index: number | null; // null if no field is being edited
  fieldType: EditableRequirementField | null; // null if no specific field type is selected
};

export const EmptyRequirementEditState: RequriementEditState = {
  index: null,
  fieldType: null,
};

const ScreeningQuestionsStep = ({
  requirements,
  requirementsHandlers,
  requirementItemBeingEdited,
  setRequirementItemBeingEdited,
}) => {
  const handleDragStart = () => {
    // Exit editing mode when starting to drag
    // setRequirementBeingEdited(undefined);
    setRequirementItemBeingEdited(EmptyRequirementEditState);
  };
  const handleDragEnd = useCallback(
    (result) => {
      if (!result.destination) return;
      requirementsHandlers.reorder({
        from: result.source.index,
        to: result.destination.index,
      });
    },
    [requirementsHandlers]
  );

  const handleDeleteRequirement = useCallback(
    (i: number) => () => {
      setRequirementItemBeingEdited(EmptyRequirementEditState);
      // setRequirementBeingEdited(undefined);
      requirementsHandlers.remove(i);
    },
    [requirementsHandlers, setRequirementItemBeingEdited]
  );

  const handleAddRequirement = () => {
    requirementsHandlers.append({
      question: '',
      llmGradingInstructions: '',
      importance: RequirementImportance.Normal,
      failIfZero: false,
    });
    // setRequirementBeingEdited(requirements.length);
  };
  const handleFieldChange =
    (index: number, field: keyof CandidateRequirement) =>
    (value: string | RequirementImportance | boolean) => {
      requirementsHandlers.setItemProp(index, field, value);
      if (field === 'importance' && value === RequirementImportance.Info) {
        requirementsHandlers.setItemProp(index, 'failIfZero', false);
      }
    };

  return (
    <>
      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <div>
          <Table className='requirementTable'>
            <Table.Thead>
              <Table.Tr>
                <Table.Th
                  // draggable
                  style={{
                    width: '3%',
                  }}
                />
                <Table.Th
                  style={{
                    width: '40%',
                  }}
                >
                  Question
                  <Input.Label required />
                  <Tooltip label='Questions worded as they will be asked during the screen'>
                    <IconHelp
                      size={16}
                      style={{
                        marginLeft: '4px',
                        position: 'relative',
                        top: '3px',
                        // color: '#868e96',
                      }}
                    />
                  </Tooltip>
                </Table.Th>
                <Table.Th
                  style={{
                    width: '40%',
                  }}
                >
                  Grading Instructions{' '}
                  <Tooltip label='Rules for how to score response on a scale from 0 to 100'>
                    <IconHelp
                      size={16}
                      style={{
                        marginLeft: '4px',
                        position: 'relative',
                        top: '3px',
                        // color: '#868e96',
                      }}
                    />
                  </Tooltip>
                </Table.Th>
                <Table.Th
                  style={{
                    width: '10%',
                  }}
                >
                  Fail if Zero{' '}
                  <Tooltip label='Set overall candidate score to 0 if question score is 0'>
                    <IconHelp
                      size={16}
                      style={{
                        marginLeft: '4px',
                        position: 'relative',
                        top: '3px',
                      }}
                    />
                  </Tooltip>
                </Table.Th>
                <Table.Th
                  style={{
                    width: '12%',
                  }}
                >
                  Importance{' '}
                  <Tooltip label='Weight of the question score toward the overall score. Info is not weighted in the overall score'>
                    <IconHelp
                      size={16}
                      style={{
                        marginLeft: '4px',
                        position: 'relative',
                        top: '3px',
                        // color: '#868e96',
                      }}
                    />
                  </Tooltip>
                </Table.Th>
                <Table.Th
                  style={{
                    width: '5%',
                  }}
                />
              </Table.Tr>
            </Table.Thead>
            <Droppable droppableId='requirements'>
              {(provided) => (
                <Table.Tbody
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {requirements?.map(
                    (
                      {
                        question,
                        llmGradingInstructions,
                        importance,
                        failIfZero,
                      },
                      index
                    ) => {
                      const isIdxEdited =
                        requirementItemBeingEdited.index === index;
                      const fieldBeingEdited =
                        requirementItemBeingEdited.fieldType;

                      return (
                        <Draggable
                          key={index}
                          draggableId={`req-${index}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Table.Tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={{
                                ...provided.draggableProps.style,
                                background: snapshot.isDragging
                                  ? 'var(--salv-dark-0)'
                                  : 'transparent',
                              }}
                            >
                              <Table.Td
                                {...provided.dragHandleProps}
                                style={{
                                  width: '3%',
                                }}
                                className='gripTd'
                              >
                                <IconGripVertical
                                  size={18}
                                  className='dragIcon'
                                />
                              </Table.Td>
                              <Table.Td
                                style={{
                                  width: '40%',
                                }}
                              >
                                <EditableField
                                  editing={
                                    isIdxEdited &&
                                    fieldBeingEdited ===
                                      EditableRequirementField.Question
                                  }
                                  value={question}
                                  onChange={handleFieldChange(
                                    index,
                                    'question'
                                  )}
                                  placeholder='What is your highest level of education?'
                                  setEditing={() => {
                                    setRequirementItemBeingEdited({
                                      fieldType:
                                        EditableRequirementField.Question,
                                      index: index,
                                    });
                                  }}
                                  description={
                                    importance ===
                                    RequirementImportance.GradeOnly
                                      ? '* Will not be asked during screen. Grade extracted from entire conversation context'
                                      : ''
                                  }
                                  shaded={
                                    importance ===
                                    RequirementImportance.GradeOnly
                                  }
                                />
                              </Table.Td>
                              <Table.Td
                                style={{
                                  width: '40%',
                                }}
                              >
                                <EditableField
                                  editing={
                                    isIdxEdited &&
                                    fieldBeingEdited ===
                                      EditableRequirementField.Grading
                                  }
                                  value={llmGradingInstructions}
                                  onChange={handleFieldChange(
                                    index,
                                    'llmGradingInstructions'
                                  )}
                                  setEditing={() => {
                                    setRequirementItemBeingEdited({
                                      fieldType:
                                        EditableRequirementField.Grading,
                                      index: index,
                                    });
                                  }}
                                  disabled={false}
                                  placeholder='Score 100 if the candidate has a bachelors or higher. Score 50 if the candidate graduated high school or equivalent. Score 0 if the candidate did not graduate high school.'
                                />
                              </Table.Td>
                              <Table.Td
                                style={{
                                  width: '10%',
                                }}
                              >
                                <div>
                                  <Switch
                                    color='var(--salv-dark-6)'
                                    checked={failIfZero}
                                    onChange={(e) =>
                                      handleFieldChange(
                                        index,
                                        'failIfZero'
                                      )(e.currentTarget.checked)
                                    }
                                    disabled={
                                      importance === RequirementImportance.Info
                                    }
                                  />
                                </div>
                              </Table.Td>
                              <Table.Td
                                style={{
                                  width: '10%',
                                }}
                              >
                                <BadgeSelector
                                  value={importance}
                                  onChange={handleFieldChange(
                                    index,
                                    'importance'
                                  )}
                                />
                              </Table.Td>
                              <Table.Td
                                onClick={(e) => e.stopPropagation()}
                                style={{
                                  width: '5%',
                                }}
                              >
                                <Group w='max-content' gap='xs'>
                                  {/* <ActionIcon
                                            variant={
                                              editing ? 'filled' : 'subtle'
                                            }
                                            onClick={handleRowClick(index)}
                                          >
                                            {editing ? (
                                              <IconCheck size={16} />
                                            ) : (
                                              <IconPencil size={16} />
                                            )}
                                          </ActionIcon> */}
                                  <ActionIcon
                                    variant='subtle'
                                    color='red'
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteRequirement(index)();
                                    }}
                                  >
                                    <IconTrash size={16} />
                                  </ActionIcon>
                                </Group>
                              </Table.Td>
                            </Table.Tr>
                          )}
                        </Draggable>
                      );
                    }
                  )}
                  {provided.placeholder}
                </Table.Tbody>
              )}
            </Droppable>
          </Table>
        </div>
      </DragDropContext>
      <Flex justify='center'>
        <Button
          leftSection={<IconPlus size={20} />}
          onClick={(e) => {
            e.stopPropagation();
            handleAddRequirement();
          }}
          variant='outline'
        >
          Add Requirement
        </Button>
      </Flex>
    </>
  );
};

const EditableField = ({
  editing,
  value,
  onChange,
  placeholder,
  setEditing,
  disabled = false,
  description = '',
  shaded = false,
}) => {
  // if (editing) {
  return (
    <Textarea
      autosize
      minRows={2}
      value={disabled ? '' : value}
      onChange={(e) => onChange(e.target.value)}
      onClick={() => {
        setEditing();
      }}
      className={`editableField tableTextArea editableText ${editing ? 'editingBorder' : ''}`}
      placeholder={disabled ? '' : placeholder}
      disabled={disabled}
      // label='Input label'
      description={description}
      style={{ backgroundColor: shaded ? 'var(--salv-dark-0)' : '' }}
      // unstyled
    />
  );
};

export default ScreeningQuestionsStep;
