import React, { useState, useEffect } from 'react';

const NUM_BARS = 7;

interface MicBarsProps {
  // This function allows MicBars to “register” a callback for mic level updates.
  getMicLevelUpdates: (callback: (level: number) => void) => void;
}

const MicBars: React.FC<MicBarsProps> = ({ getMicLevelUpdates }) => {
  const [micLevel, setMicLevel] = useState(0);

  useEffect(() => {
    // When the component mounts, call the passed-in function.
    // We hand it a callback that MicBars can use to update its internal micLevel state.
    getMicLevelUpdates((newLevel: number) => {
      setMicLevel(newLevel);
    });
  }, [getMicLevelUpdates]);

  // Compute how many bars to fill based on micLevel.
  const micIndex = Math.min(Math.floor(micLevel * NUM_BARS), NUM_BARS);

  return (
    <div style={{ display: 'flex', gap: '8px', marginBottom: '16px' }}>
      {Array.from({ length: NUM_BARS }).map((_, barIndex) => {
        const isFilled = barIndex < micIndex;
        return (
          <div
            key={barIndex}
            style={{
              width: '10px',
              height: '40px',
              background: isFilled ? '#62de62' : 'rgba(0,255,0,0.15)',
              borderRadius: '2px',
              transition: 'background 0.2s ease',
            }}
          />
        );
      })}
    </div>
  );
};

export default React.memo(MicBars);
