import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Text, Progress, Skeleton, Popover } from '@mantine/core';
import './CampaignDetailStatsRow.css';
import env from 'env';
import { convertSecondsToHoursMinutes } from 'utils/dateUtils';
import { IconHelp } from '@tabler/icons-react';

interface CampaignDetailStats {
  calls_attempted: number;
  sum_call_length_sec: number;
  total_sms_sent: number;

  total_candidates: number;
  completed_candidates: number;
}

interface CampaignDetailStatsRowProps {
  campaignId: string;
  isWebCall: boolean;
}

const SECONDS_SAVED_PER_CALL_ATTEMPT = 60;
const SECONDS_PER_MSG_SENT = 30;

const getTimeSaved = (s: CampaignDetailStats) => {
  const call_attempt_seconds =
    s.calls_attempted * SECONDS_SAVED_PER_CALL_ATTEMPT;
  const msgs_seconds = s.total_sms_sent * SECONDS_PER_MSG_SENT;
  const call_time = s.sum_call_length_sec;
  // TODO: number of emails
  const total_seconds = call_attempt_seconds + msgs_seconds + call_time;

  return convertSecondsToHoursMinutes(total_seconds);
};

type StatItemProps = {
  value: number;
  label: string;
  explanation?: React.ReactNode; // Make explanation optional
};

const StatItem: React.FC<StatItemProps> = ({ value, label, explanation }) => {
  return (
    <div className='stat-item'>
      <Text fw={600} color='var(--mantine-color-blue-6)' size='md'>
        {value}
      </Text>
      <Text
        size='sm'
        fw={500}
        className='stat-item-text'
        c='var(--salv-dark-9)'
      >
        {label}
      </Text>
      {explanation && <>{explanation}</>}
    </div>
  );
};

const CampaignDetailStatsRow: React.FC<CampaignDetailStatsRowProps> = ({
  campaignId,
  isWebCall,
}) => {
  const [stats, setStats] = useState<CampaignDetailStats | null>(null);

  useEffect(() => {
    const fetchStats = async () => {
      await new Promise((r) => setTimeout(r, 1000));
      try {
        const response = await axios.post(
          `${env.REACT_APP_SERVER_URL}/campaigns/campaign/${campaignId}/stats`
        );
        console.log('response', response.data.campaign_stats);
        setStats(response.data.campaign_stats);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (campaignId) {
      fetchStats();
    }
  }, [campaignId]);

  if (isWebCall) {
    return (
      <div
        className='stat-row-container'
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
      ></div>
    );
  }

  if (!stats) {
    // TODO: make an outer container with a fixed height
    return (
      <div
        className='stat-row-container'
        style={{ paddingTop: '26px', paddingBottom: '25px' }}
      >
        <Skeleton height={8} radius='xl' width='8%' />{' '}
        <Skeleton height={8} radius='xl' width='30%' />
      </div>
    );
  }

  function AlignedText({ leftContent, rightContent }) {
    return (
      <div className='aligned-text-container'>
        <b className='aligned-text-left'>{leftContent}</b>
        <span className='aligned-text-right'>{rightContent}</span>
      </div>
    );
  }

  const renderForumla = () => {
    return (
      <Popover width={200} position='bottom' shadow='md'>
        <Popover.Target>
          <IconHelp
            size={16}
            style={{
              marginLeft: '-3px',
              position: 'relative',
              top: '0px',
            }}
            className='help-icon'
            color='var(--salv-dark-6)'
          />
        </Popover.Target>
        <Popover.Dropdown
          style={{ width: 'calc(10.5rem * var(--mantine-scale))' }}
        >
          <AlignedText
            leftContent={convertSecondsToHoursMinutes(
              stats.sum_call_length_sec
            )}
            rightContent='Calling'
          />
          {!isWebCall && (
            <AlignedText
              leftContent={convertSecondsToHoursMinutes(
                stats.total_sms_sent * SECONDS_PER_MSG_SENT
              )}
              rightContent='Messaging'
            />
          )}
          {!isWebCall && (
            <AlignedText
              leftContent={convertSecondsToHoursMinutes(
                stats.calls_attempted * SECONDS_SAVED_PER_CALL_ATTEMPT
              )}
              rightContent='Call Attempts'
            />
          )}
        </Popover.Dropdown>
      </Popover>
    );
  };

  return (
    <div className='stat-row-container'>
      <div className='progress-container'>
        <Progress
          value={(stats.completed_candidates * 100) / stats.total_candidates}
          style={{ width: '150px' }}
          color='var(--salv-dark-2)'
          //   color='var(--mantine-color-blue-3)'
        />
        <Text size='xs' fw={400} className='stat-item-text'>
          {/* {`${stats.completed_candidates} / ${stats.total_candidates}`}{' '} */}
          {`${((stats.completed_candidates * 100) / (stats.total_candidates || 1)).toFixed(0)}%`}
          {' Sequences Complete'}
        </Text>
      </div>
      <div className='campaign-detail-stats-row'>
        <StatItem
          value={stats.total_candidates}
          label={stats.total_candidates === 1 ? 'Candidate' : 'Candidates'}
        />
        <StatItem
          value={stats.calls_attempted}
          label={stats.calls_attempted === 1 ? 'Call' : 'Calls'}
        />
        <StatItem
          value={stats.total_sms_sent}
          label={stats.total_sms_sent === 1 ? 'Message' : 'Messages'}
        />

        {/* <StatItem
          value={convertSecondsToHoursMinutes(stats.sum_call_length_sec || '')}
          label='On Phone'
        /> */}
        <StatItem
          value={getTimeSaved(stats || '0m')}
          label='Saved'
          explanation={renderForumla()}
        />
      </div>
    </div>
  );
};

export default CampaignDetailStatsRow;
