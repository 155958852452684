import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
  Table,
  Title,
  Tooltip,
  Button,
  Checkbox,
  Loader,
  Select,
  Switch,
  Pagination,
} from '@mantine/core';
import {
  IconFileDownload,
  IconDownload,
  IconPhone,
  IconMail,
  IconEdit,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import env from 'env';

import axios from '../../api/axiosConfig';
import './SourcingPage.css';
import PDFViewer from '../../components/PDFViewer.js';
// eslint-disable-next-line import/order
import SourcingModal from './components/SourcingModal';

import './SourcingPage.css';
import SourcingNameFilter from './components/SourcingNameFilter';
import SourcingLocationFilter from './components/SourcingLocationFilter';
import SourcingDateFilter from './components/SourcingDateFilter';
import { formatPhoneNumber } from '../../utils/phoneUtils';
import { GoogleMapsPlaceWithDescription } from '../../components/location-autocomplete/types';
import { daysAgo } from '../../utils/dateUtils';
import { DateSelectionMode } from './components/types';
import 'css/common.css';

type QualificationDetail = {
  value: string;
  thinking: string;
};

type Candidate = {
  id: string; // defined by front end
  type: 'internal';
  city: string;
  state: string;
  milesFromJob: number;
  candidateId: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  zipCode: string;
  resumeUrl: string;
  checked: boolean;
  embeddingScore: number;
  llmScore: number;
  qualificationSummary: string;
  qualificationsMap: Record<string, QualificationDetail>;
  resumeText: string | undefined;
  yearsOfExperience: string;
  lastViewedDate: Date;
};

type DownloadMonsterCandidatePayload = {
  md5EmailAddress: string;
  textResumeID: string;
};

function removeParentheses(str) {
  return str.replace(/\([^)]*\)/g, '');
}

type MonsterCandidate = {
  id: string; // defined by front end
  alreadyDownloaded: boolean; // defined by front end
  type: 'monster';
  checked: boolean;
  candidate_info: Candidate | null;
  identity: {
    name: string;
    resumeModifiedDate: string;
    md5EmailAddress: string; // best ID
    textResumeID: string;
  };
  location: {
    city: string;
    state: string;
    milesFromJob: number;
  };
  relevance: {
    experience: {
      start: string;
      company: {
        matched: string;
        name: string;
      };
      title: {
        name: string;
        matched: string;
      };
    };
    skills: {
      matched: string;
      name: string;
      yearsOfExperience: number;
    }[];
  };
};

type keyword = {
  value: string;
  required: boolean;
};

type JobSummary = {
  jobDescription: string;
  experiences: keyword[];
  skills: keyword[];
  keywords: keyword[];
  jobTitles: keyword[];
  idealCandidateSummary: string;
  location: GoogleMapsPlaceWithDescription | undefined;
  radius: number;
};

// just a temporary default job so we don't need to re-enter to test
const DEFAULT_JOB = `EXAMPLE JOB DESCRIPTION:

Location: Los Angeles, CA

Responsibilities:

Operate, program, and set up 3- and 5-axis CNC mills and CNC lathe
Program mill and lathe tool paths using CAM software
Machine parts using detailed drawings and CAD models
Design and manufacture of tooling and fixtures for use in manufacture of parts
Communicate with engineers verbally and written
Qualifications:

Experience in the field of machining precise, complex, one-off components.
Advanced understanding of machining theory, work practices and shop mathematics.
Perform complex machining operation on highly complex geometry with precision tolerancing
Extensive experience using CAM systems to analyze components, set-ups, tooling and fixturing, as well as to produce efficient, accurate toolpaths for use on a variety of CNC machine tools.
Ability to use CAD systems to manipulate electronic models of machined components and assemblies, as well as to model fixturing, tooling and machine tool components to aid in manufacturing processes.
Demonstrated knowledge, skill and work experience in performing set-ups, testing, troubleshooting, repair, calibration, operation and maintenance of sophisticated mechanical, shop related, and scientific apparatus, equipment and systems.
Knowledge of the GD&T, with the ability to read and correctly decode blueprints, sketches, parts lists, layout and assembly drawings and operation, calibration and repair manuals.
Knowledge of and ability to select, set-up and use appropriate hand, power, and specialized machine tools
Determine and source required manufacturing tooling and fixtures
Desired:

Familiarity with SolidWorks
Siemens NX CAM experience is a plus
Heidenhain control experience is a plus
Simultaneous 5-axis programming experience
Experience machining copper alloys and high strength stainless steel alloys
`;
const DEFAULT_MAX_CANDIDATES = 25;

function capitalizeWords(str: string) {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
}

// does not maintain captilalization of non-first chars
function capitalizeName(str: string) {
  return str.toLocaleLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
}

const CopyableText = ({ text, children }) => {
  const [tooltipText, setTooltipText] = useState('Copy');

  const handleCopy = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setTooltipText('Copied!');
        setTimeout(() => setTooltipText('Copy'), 2000);
      })
      .catch((err) => {
        console.error('Failed to copy text:', err);
      });
  };

  return (
    <Tooltip label={tooltipText} position='top' withArrow>
      <span
        onClick={handleCopy}
        style={{
          cursor: 'pointer',
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        {children}
      </span>
    </Tooltip>
  );
};

const EMPTY_JOB: JobSummary = {
  jobDescription: DEFAULT_JOB,
  skills: [],
  experiences: [],
  keywords: [],
  jobTitles: [],
  idealCandidateSummary: '',
  location: undefined,
  radius: 20,
};

enum SearchDatabaseOptions {
  internal = 'Internal',
  monster = 'Monster',
}

type SearchDatabaseOption =
  | SearchDatabaseOptions.internal
  | SearchDatabaseOptions.monster;

const SourcingPage = () => {
  const [currentInternalPage, setCurrentInternalPage] = useState(1);
  const [searchingInternal, setSearchingInternal] = useState(false);
  const [searchingMonster, setSearchingMonster] = useState(false);
  const [downloadingMonster, setDownloadingMonster] = useState(false);
  const [lastSeenSelectionMode, setLastSeenSelectionMode] =
    useState<DateSelectionMode>('after');
  const [lastSeenStartDate, setLastSeenStartDate] = useState<Date | null>(null);
  const [lastSeenEndDate, setLastSeenEndDate] = useState<Date | null>(null);

  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [monsterCandidates, setMonsterCandidates] = useState<
    MonsterCandidate[]
  >([]);
  // we keep track of offset seperately since we post-filter monster results but need to remember where we were in their pagination
  const [monsterOffset, setMonsterOffset] = useState(0);
  const [focusedCandidateId, setFocusedCandidateId] = useState('');
  const [jobDescriptionModalOpen, setJobDescriptionModalOpen] = useState(false);
  const maxCandidates = DEFAULT_MAX_CANDIDATES;
  const [jobSummary, setJobSummary] = useState<JobSummary>(() => {
    const storedJobSummary = localStorage.getItem('lastJobSummary');
    return storedJobSummary ? JSON.parse(storedJobSummary) : EMPTY_JOB;
  });
  const [modalPage, setModalPage] = useState(
    localStorage.getItem('lastJobSummary') ? 1 : 0
  );
  const [analyzing, setAnalyzing] = useState(false);

  const [includeDownloaded, setIncludeDownloaded] = useState(true);

  const [candidateIdFilter, setCandidateIdFilter] = useState('');

  const [searchDB, setSearchDB] = useState<SearchDatabaseOption>(
    SearchDatabaseOptions.internal
  );

  const selectCandidate = useCallback(
    (candidateId: string) => {
      setCandidates((prevCandidates) =>
        prevCandidates.map((item) =>
          item.candidateId === candidateId
            ? { ...item, checked: !item.checked }
            : item
        )
      );
    },
    [setCandidates]
  );

  useEffect(() => {
    if (jobSummary) {
      console.log('setting last job summary');
      localStorage.setItem('lastJobSummary', JSON.stringify(jobSummary));
    }
  }, [jobSummary]);

  const selectMonsterCandidate = (md5EmailAddress: string) => {
    setMonsterCandidates(
      monsterCandidates.map((item) =>
        item.identity.md5EmailAddress === md5EmailAddress
          ? { ...item, checked: !item.checked }
          : item
      )
    );
  };

  const isInternal = useMemo(() => {
    return searchDB === SearchDatabaseOptions.internal;
  }, [searchDB]);

  const isMonster = useMemo(() => {
    return searchDB === SearchDatabaseOptions.monster;
  }, [searchDB]);

  const focusedCandidate = useMemo(() => {
    if (
      searchDB === SearchDatabaseOptions.internal &&
      candidates &&
      candidates.find((c) => c.id === focusedCandidateId)
    ) {
      return candidates.find((c) => c.id === focusedCandidateId);
    } else if (
      searchDB === SearchDatabaseOptions.monster &&
      monsterCandidates &&
      monsterCandidates.find((c) => c.id === focusedCandidateId)
    ) {
      return monsterCandidates.find((c) => c.id === focusedCandidateId);
    }
  }, [candidates, focusedCandidateId, searchDB, monsterCandidates]);

  const selectedCandidates = candidates.filter((c) => c.checked);
  const numSelectedCandidates = selectedCandidates.length;

  const numSelectedMonsterCandidates = monsterCandidates.filter(
    (c) => c.checked
  ).length;

  // Update the resume text of a candidate
  const getJobSourceParameters = useCallback(async () => {
    setCandidates([]);
    setFocusedCandidateId('');
    setAnalyzing(true);
    setJobSummary((prevSummary) => ({
      ...prevSummary,
      skills: [],
      experiences: [],
      jobTitles: [],
      idealCandidateSummary: '',
      location: undefined,
    }));
    try {
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/job_source_parameters`,
        {
          jobDescription: jobSummary.jobDescription,
          amount: maxCandidates,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const {
        experiences,
        skills,
        keywords,
        idealCandidateSummary,
        previousJobTitles,
      } = response.data.jobDescriptionParameters;

      // Hack while required is broken
      const setRequiredFalse = (array) => {
        array.forEach((obj) => {
          obj.required = false;
        });
      };

      setRequiredFalse(experiences);
      setRequiredFalse(skills);
      setRequiredFalse(keywords);
      setRequiredFalse(previousJobTitles);
      // End hack
      setJobSummary((prevSummary) => ({
        ...prevSummary,
        skills: skills,
        experiences: experiences,
        jobTitles: previousJobTitles,
        keywords: keywords,
        idealCandidateSummary: idealCandidateSummary,
      }));
    } catch (error) {
      console.error('Error fetching candidates:', error);
    }
    setAnalyzing(false);
    setModalPage(1);
  }, [jobSummary, setCandidates, maxCandidates]);

  // Update the resume text of a candidate
  const updateCandidateInfo = useCallback(
    async (c: Candidate) => {
      try {
        if (c.resumeText && c.resumeText !== '') {
          // already updated
          return;
        }
        const response = await axios.post(
          `${env.REACT_APP_SERVER_URL}/candidate_info`,
          {
            candidateId: c.candidateId,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        const { resumeText, jobApplications } = response.data.candidateInfo;
        if (!resumeText) {
          c.resumeText = ''; // hack for loading
          console.error('no resume text');
          return;
        }
        setCandidates((currentCandidates) =>
          currentCandidates.map((candidate) =>
            candidate.candidateId === c.candidateId
              ? {
                  ...candidate,
                  resumeText: resumeText,
                  jobApplications: jobApplications,
                }
              : candidate
          )
        );
        setMonsterCandidates((currentCandidates) =>
          currentCandidates.map((candidate) =>
            candidate.candidate_info?.candidateId === c.candidateId
              ? {
                  ...candidate,
                  candidate_info: {
                    ...candidate.candidate_info,
                    resumeText: resumeText,
                    jobApplications: jobApplications,
                  },
                }
              : candidate
          )
        );
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    },
    [setCandidates]
  );

  function formatDate(date: Date): string {
    return dayjs(date).format('M/D/YY');
  }

  const createFilters = useCallback(
    (withOverrides: any = undefined) => {
      if (!candidateIdFilter && !jobSummary?.location?.geometry?.location) {
        return {};
      }
      const filters = {
        identifier: candidateIdFilter,
      };
      if (jobSummary?.location?.geometry?.location) {
        filters['location'] = {
          location: jobSummary?.location?.geometry?.location,
          radius: jobSummary?.radius,
        };
      }
      if (lastSeenEndDate || lastSeenStartDate) {
        filters['excludeViewed'] = {};
        if (lastSeenEndDate) {
          filters['excludeViewed']['endDate'] =
            dayjs(lastSeenEndDate).toISOString() || '';
        }
        if (lastSeenStartDate) {
          filters['excludeViewed']['startDate'] =
            dayjs(lastSeenStartDate).toISOString() || '';
        }
      }
      if (withOverrides && 'identifier' in withOverrides) {
        filters['identifier'] = withOverrides['identifier'];
      }
      return filters;
    },
    [jobSummary, candidateIdFilter, lastSeenEndDate, lastSeenStartDate]
  );

  const lowestEmbeddingScoreId = useMemo(() => {
    if (!candidates.length) {
      return '';
    }
    let objWithLowestEmbScore = candidates.reduce((lowest, current) => {
      return current.embeddingScore < lowest.embeddingScore ? current : lowest;
    }, candidates[0]);
    return objWithLowestEmbScore.candidateId;
  }, [candidates]);

  function setAllKeywordsRequiredFalse(jobSummary) {
    // List of all arrays that are of type `keyword[]` in JobSummary
    const keywordArrays = ['experiences', 'skills', 'keywords', 'jobTitles'];

    keywordArrays.forEach((key) => {
      if (Array.isArray(jobSummary[key])) {
        jobSummary[key].forEach((item) => {
          if (item && typeof item === 'object' && 'required' in item) {
            item.required = false; // Set the `required` property to false
          }
        });
      }
    });
  }

  const fetchInternalCandidates = useCallback(
    async (offset: boolean, withOverrides: any = undefined) => {
      const filters = createFilters(withOverrides);

      setJobDescriptionModalOpen(false);
      if (!offset) {
        setCurrentInternalPage(1);
        setCandidates([]);
      }

      setSearchingInternal(true);
      setFocusedCandidateId('');
      try {
        setAllKeywordsRequiredFalse(jobSummary);
        const response = await axios.post(
          `${env.REACT_APP_SERVER_URL}/source_candidates_internal`,
          {
            jobSummary: jobSummary, // todo: remade location below, so can remove from request
            amount: maxCandidates,
            offset: offset ? candidates.length : 0, // deprecated
            lastId: offset ? lowestEmbeddingScoreId : '', // this takes priority now. offset is deprecated for internal
            filters: filters,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        if (
          response.data.errorMessage === 'unable to find location information'
        ) {
          alert('Job must include location information ');
        }
        response.data.candidates.forEach((c) => {
          c.checked = c.llmScore >= 0.5;
        });
        const transformedCandidates: Candidate[] = response.data.candidates.map(
          (candidate: Omit<Candidate, 'type'>) => ({
            ...candidate,
            type: 'internal',
            id: candidate.candidateId,
          })
        );
        setCandidates((prevCandidates) => [
          ...prevCandidates,
          ...transformedCandidates,
        ]);
        if (response.data.candidates.length > 0) {
          updateCandidateInfo(response.data.candidates[0]);
        }
      } catch (error) {
        console.error('Error fetching candidates:', error);
      }
      setSearchingInternal(false);
    },
    [
      setCandidates,
      maxCandidates,
      updateCandidateInfo,
      jobSummary,
      candidates,
      createFilters,
      lowestEmbeddingScoreId,
    ]
  );

  const fetchMonsterCandidates = useCallback(
    async (offset: boolean = false) => {
      setJobDescriptionModalOpen(false);
      if (!offset) {
        setMonsterCandidates([]);
      }
      setSearchingMonster(true);
      try {
        const response = await axios.post(
          `${env.REACT_APP_SERVER_URL}/source_candidates_monster`,
          {
            jobSummary: jobSummary,
            amount: maxCandidates,
            lastId: '', // unused for monster
            offset: offset ? monsterOffset : 0,
            includeDownloaded: includeDownloaded,
            filters: createFilters(),
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        const newCandidates: MonsterCandidate[] =
          response.data.candidates.candidates.map(
            (candidate: Omit<MonsterCandidate, 'type'>) => ({
              ...candidate,
              type: 'monster',
              checked: true,
              id: candidate.identity.md5EmailAddress,
              alreadyDownloaded: candidate.candidate_info ? true : false,
            })
          );
        const newOffset: number = response.data.newOffset;
        setMonsterOffset(newOffset);

        setMonsterCandidates((prevCandidates) => {
          if (offset) return [...prevCandidates, ...newCandidates];
          else return newCandidates;
        });
      } catch (error) {
        console.error('Error fetching monster canididates:', error);
      }
      setSearchingMonster(false);
    },
    [
      setMonsterOffset,
      monsterOffset,
      setMonsterCandidates,
      maxCandidates,
      jobSummary,
      includeDownloaded,
      createFilters,
    ]
  );

  const fetchCandidates = useCallback(
    (offset, withOverrides: any = undefined) => {
      fetchInternalCandidates(offset, withOverrides);
      fetchMonsterCandidates(offset);
    },
    [fetchInternalCandidates, fetchMonsterCandidates]
  );

  const csvFieldMap = useMemo(
    () => [
      { fieldName: 'fullName', header: 'Candidate Full Name' },
      { fieldName: 'phoneNumber', header: 'Phone Number' },
      { fieldName: 'email', header: 'Email' },
      { fieldName: 'city', header: 'Current City' },
    ],
    []
  );

  const convertToCSV = useCallback(
    (objArray) => {
      const array =
        typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      let str = '';

      const headers = csvFieldMap.map((f) => f.header);
      str += headers.join(',') + '\r\n';

      for (let i = 0; i < array.length; i++) {
        let line = csvFieldMap.map((f) => array[i][f.fieldName]).join(',');
        str += line + '\r\n';
      }

      return str;
    },
    [csvFieldMap]
  );

  const downloadCSV = useCallback(
    (selectedCandidates) => {
      const csvData = convertToCSV(selectedCandidates);
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'download.csv';
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    [convertToCSV]
  );

  const downloadMonsterResumes = useCallback(
    async (
      candidates: DownloadMonsterCandidatePayload[]
    ): Promise<MonsterCandidate[]> => {
      if (downloadingMonster) {
        alert('Error: already downloading)');
        return [];
      }
      setDownloadingMonster(true);
      try {
        const filters = createFilters();
        const response = await axios.post(
          `${env.REACT_APP_SERVER_URL}/download_monster_resumes`,
          {
            resumes: candidates,
            force: true, // WARNING: this will override backend logic to block redownloads
            jobSummary: jobSummary,
            filters: filters,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        const resumeUpdates = response.data.monsterResumeDetails;
        if (!resumeUpdates) {
          alert('error downloading!');
        }
        const newCandidates = monsterCandidates.map((candidate) => {
          const matchingResumeUpdate = resumeUpdates.find(
            (update) =>
              update.identity.md5EmailAddress ===
              candidate.identity.md5EmailAddress
          );
          return matchingResumeUpdate
            ? { ...candidate, ...matchingResumeUpdate }
            : candidate;
        });
        setDownloadingMonster(false);
        setMonsterCandidates(newCandidates);
        return newCandidates;
      } catch (error) {
        setDownloadingMonster(false);
        console.error('Error fetching monster resume details:', error);
        return [];
      }
    },
    [jobSummary, monsterCandidates, downloadingMonster, createFilters]
  );

  const downloadCheckedMonsterCandidates = useCallback(async () => {
    const checkedCandidatesNeedInfo: DownloadMonsterCandidatePayload[] =
      monsterCandidates
        .filter((m) => m.checked)
        .filter((m) => !m.candidate_info)
        .map((c) => ({
          md5EmailAddress: c.identity.md5EmailAddress,
          textResumeID: c.identity.textResumeID,
        }));

    let allCandidates = monsterCandidates;
    if (checkedCandidatesNeedInfo && checkedCandidatesNeedInfo.length > 0) {
      allCandidates = await downloadMonsterResumes(checkedCandidatesNeedInfo);
      if (!allCandidates) {
        alert('error getting updates, no csv');
      }
    }

    const csvCandidates = allCandidates
      .filter((m) => m.checked)
      .filter((m) => m.candidate_info)
      .map((m) => m.candidate_info);

    downloadCSV(csvCandidates);
  }, [monsterCandidates, downloadMonsterResumes, downloadCSV]);

  const renderDownloadButton = useMemo(
    () => () =>
      searchDB === SearchDatabaseOptions.internal ? (
        <Button
          onClick={() => downloadCSV(selectedCandidates)}
          size='sm'
          className='top-button download-button'
          disabled={numSelectedCandidates === 0}
          style={{ opacity: numSelectedCandidates === 0 ? 0.5 : 1 }}
        >
          CSV{' '}
          {numSelectedCandidates > 0 ? '(' + numSelectedCandidates + ')' : ''}{' '}
          <IconDownload size={20} style={{ marginLeft: '8px' }} />
        </Button>
      ) : (
        <Button
          onClick={downloadCheckedMonsterCandidates}
          size='sm'
          className='top-button download-button'
          disabled={
            monsterCandidates.filter((m) => m.checked).length === 0 ||
            downloadingMonster
          }
          style={{
            opacity:
              monsterCandidates.filter((m) => m.checked).length === 0 ? 0.5 : 1,
          }}
        >
          CSV{' '}
          {numSelectedMonsterCandidates > 0
            ? '(' + numSelectedMonsterCandidates + ')'
            : ''}
          <IconDownload size={20} style={{ marginLeft: '6px' }} />
        </Button>
      ),
    [
      downloadCSV,
      downloadCheckedMonsterCandidates,
      numSelectedCandidates,
      monsterCandidates,
      searchDB,
      selectedCandidates,
      downloadingMonster,
      numSelectedMonsterCandidates,
    ]
  );

  const handleCurrentInternalPageChange = (pageNum: number) => {
    if (candidates.length % maxCandidates !== 0) {
      console.error('done loading');
    } else if (pageNum > candidates.length / maxCandidates) {
      fetchInternalCandidates(true);
    }
    setCurrentInternalPage(pageNum);
  };

  const renderPdf = useMemo(
    () => (c: Candidate) => {
      if (!c.resumeUrl || c.resumeText === '') {
        return <></>;
      }
      if (c.resumeText === undefined) {
        return (
          <div
            style={{
              height: '100px',
              textAlign: 'center',
              alignContent: 'center',
            }}
          >
            <Loader size='sm' />
          </div>
        );
      }
      return <PDFViewer fileUrl={c.resumeUrl} backupText={c.resumeText} />;
    },
    []
  );

  const dateStringToMY = (dateString: string) => {
    const date = new Date(dateString);

    const month = date.toLocaleString('en-US', { month: 'long' });
    const year = date.getUTCFullYear();

    return `${month}, ${year}`;
  };

  const renderInternalCandidateInfo = useCallback(
    (internalCandidate, monsterIdentity) => {
      if (!internalCandidate) {
        return <h3>Error, no candidate</h3>;
      }
      return (
        <>
          <div
            style={{
              position: 'relative',
              height: '100%',
              fontSize: '14px',
            }}
          >
            <div style={{ display: 'flex' }}>
              <h3 style={{ marginBottom: '4px' }}>
                <CopyableText text={internalCandidate.fullName}>
                  {capitalizeName(internalCandidate.fullName)}
                </CopyableText>
              </h3>
            </div>
            <div
              style={{
                display: 'flex',
                marginBottom: '6px',
                alignItems: 'center',
              }}
            >
              <div style={{ display: 'flex', marginRight: '12px' }}>
                <CopyableText text={internalCandidate.phoneNumber}>
                  <IconPhone style={{ opacity: 0.7, marginRight: '2px' }} />
                  {formatPhoneNumber(internalCandidate.phoneNumber)}
                </CopyableText>
              </div>
              <div style={{ display: 'flex', marginRight: '12px' }}>
                <CopyableText text={internalCandidate.email}>
                  <IconMail style={{ opacity: 0.7, marginRight: '4px' }} />
                  {internalCandidate.email}
                </CopyableText>
              </div>
              {monsterIdentity && (
                <Button
                  size='sm'
                  variant='light'
                  disabled={downloadingMonster}
                  onClick={() =>
                    downloadMonsterResumes([
                      {
                        md5EmailAddress: monsterIdentity?.md5EmailAddress,
                        textResumeID: monsterIdentity?.textResumeID,
                      },
                    ])
                  }
                >
                  Redo Purchase{' '}
                  {<IconDownload style={{ marginLeft: '12px' }} />}
                </Button>
              )}
            </div>
            {internalCandidate?.jobApplications &&
              internalCandidate?.jobApplications.length > 0 && (
                <div style={{ marginRight: '12px' }}>
                  <h4 style={{ margin: '0px' }}>Applied to</h4>
                  {internalCandidate?.jobApplications?.map((jobApp) => (
                    <div style={{ paddingLeft: '12px' }}>
                      <span style={{ marginRight: '2px' }}>
                        {jobApp?.job?.title}
                      </span>
                      {'  '}
                      <span style={{ marginRight: '2px' }}>
                        {jobApp?.job?.city}, {jobApp?.job?.state}
                      </span>
                      {'  '}
                      <span style={{ color: 'gray' }}>
                        {'on ' + formatDate(jobApp?.date)}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            <div style={{ display: 'flex' }}>
              {internalCandidate?.qualificationsMap && (
                <div style={{ width: '100%' }}>
                  {Object.keys(internalCandidate?.qualificationsMap)
                    .filter((key) => {
                      return (
                        internalCandidate?.qualificationsMap &&
                        internalCandidate?.qualificationsMap[key]?.value ===
                          'yes'
                      );
                    })
                    .map((kw) => (
                      <>
                        <span
                          style={{
                            marginRight: '10px',
                            fontWeight: 'bold',
                          }}
                        >
                          {capitalizeWords(kw)}
                        </span>
                      </>
                    ))}
                  <br />

                  <span>Missing: </span>
                  {Object.keys(internalCandidate?.qualificationsMap)
                    .filter((key) => {
                      return (
                        internalCandidate?.qualificationsMap &&
                        internalCandidate.qualificationsMap[key]?.value ===
                          'unknown'
                      );
                    })
                    .map((kw) => (
                      <>
                        <span
                          style={{
                            marginRight: '10px',
                            fontWeight: 'bold',
                            color: '#c71212',
                          }}
                        >
                          {capitalizeWords(kw)}
                        </span>
                      </>
                    ))}
                </div>
              )}
              <div style={{ paddingTop: '6px' }}>Resume</div>
              {internalCandidate.resumeUrl && (
                <a
                  href={internalCandidate.resumeUrl}
                  style={{ color: 'black' }}
                  className='default-download-btn'
                >
                  <IconFileDownload size={35} stroke={1} />
                </a>
              )}
            </div>

            <div
              style={{ overflowY: 'scroll', maxHeight: 'calc(100% - 120px)' }}
            >
              {renderPdf(internalCandidate)}
            </div>
          </div>
        </>
      );
    },
    [renderPdf, downloadMonsterResumes, downloadingMonster]
  );

  const renderFocusedCandidate = useCallback(() => {
    switch (focusedCandidate?.type) {
      case 'internal':
        return <>{renderInternalCandidateInfo(focusedCandidate, undefined)}</>;
      case 'monster':
        if (focusedCandidate.candidate_info) {
          return (
            <>
              {renderInternalCandidateInfo(
                focusedCandidate.candidate_info,
                focusedCandidate.identity
              )}
            </>
          );
        }
        return (
          <>
            <div
              style={{
                position: 'relative',
                height: '100%',
                maxWidth: '500px',
                maxHeight: '600px',
                fontSize: '14px',
                border: '1px solid gray',
                borderRadius: '12px',
                background: 'white',
                padding: '12px',
                paddingLeft: '20px',
                margin: '6px',
              }}
            >
              <div style={{ display: 'flex' }}>
                <h2 style={{ marginBottom: '4px' }}>
                  {capitalizeName(focusedCandidate.identity.name)} (Preview)
                </h2>
              </div>
              Location: {focusedCandidate.location.city}{' '}
              {focusedCandidate.location.state} <br />
              <div>
                Last Update:{' '}
                {dateStringToMY(focusedCandidate.identity.resumeModifiedDate)}
              </div>
              <div
                style={{
                  marginTop: '8px',
                  paddingTop: '8px',
                  marginLeft: 'auto',
                }}
              >
                {focusedCandidate.candidate_info ? (
                  'Already Downloaded'
                ) : (
                  <Button
                    size='sm'
                    variant='light'
                    disabled={downloadingMonster}
                    onClick={() =>
                      downloadMonsterResumes([
                        {
                          md5EmailAddress:
                            focusedCandidate.identity.md5EmailAddress,
                          textResumeID: focusedCandidate.identity.textResumeID,
                        },
                      ])
                    }
                  >
                    Purchase Details{' '}
                    {<IconDownload style={{ marginLeft: '12px' }} />}
                  </Button>
                )}
              </div>
              <br />
              <div style={{ display: 'flex' }}>
                <div>
                  <h3 style={{ marginBottom: '2px' }}>Experience Highlight</h3>
                  <div style={{ fontWeight: 'bold' }}>
                    {focusedCandidate.relevance?.experience?.title?.name}
                  </div>
                  {focusedCandidate.relevance?.experience?.company?.name}
                  <br />
                  <div style={{ color: 'grey' }}>
                    {dateStringToMY(
                      focusedCandidate.relevance?.experience?.start
                    )}
                  </div>
                </div>
              </div>
              <h3 style={{ marginBottom: '6px' }}>Skills:</h3>
              <div>
                {focusedCandidate.relevance?.skills?.map((s) => (
                  <div>
                    {removeParentheses(s.matched)} (
                    {Math.floor(s.yearsOfExperience) + ' years'})
                  </div>
                ))}
              </div>
            </div>
          </>
        );
      default:
        return <> </>;
    }
  }, [
    focusedCandidate,
    renderInternalCandidateInfo,
    downloadMonsterResumes,
    downloadingMonster,
  ]);

  const renderInternalCandidateRow = useCallback(
    (c: Candidate, index: number, id: string, onCheck: () => void, checked) => {
      return (
        <React.Fragment key={index}>
          <div
            className={`selectable-row ${focusedCandidateId === id ? 'selected' : ''}`}
            onClick={() => {
              setFocusedCandidateId(id);
              updateCandidateInfo(c);
            }}
          >
            <Table.Tr
              style={{
                display: 'table',
                width: '100%',
                tableLayout: 'fixed',
                borderBottom: 'none',
              }}
            >
              <Table.Td style={{ width: '5%' }}>
                <Checkbox
                  key={index}
                  checked={checked}
                  onChange={onCheck}
                  style={{ position: 'relative', top: '5px', left: '1px' }}
                />
              </Table.Td>
              <Table.Td style={{ fontWeight: 'bold', width: '20%' }}>
                {capitalizeName(c.fullName)}
              </Table.Td>
              {!c.llmScore && (
                /* Hack to show phone number in the search case, llm exists is just
                an inelegant check for "was name searched"*/
                <Table.Td style={{ width: '20%' }}>
                  {formatPhoneNumber(c.phoneNumber)}
                </Table.Td>
              )}
              <Table.Td style={{ width: '20%' }}>
                {c.city} {c.state} <br />
                <div style={{ color: 'gray' }}>
                  {c?.milesFromJob && (
                    <>
                      {c?.milesFromJob?.toFixed(0)} {' miles away'}
                    </>
                  )}
                </div>
              </Table.Td>
              {c.llmScore && (
                <Table.Td style={{ width: '20%' }}>
                  Score: {c.llmScore}
                </Table.Td>
              )}
              {/* {c.embeddingScore && (
                <Table.Td style={{ width: '20%' }}>
                  Emb: {c.embeddingScore?.toFixed(4)}
                </Table.Td>
              )} */}
              {c.yearsOfExperience && (
                <Table.Td style={{ width: '20%' }}>
                  {c.yearsOfExperience} years
                  <br />
                  <div style={{ color: 'gray' }}>{'experience'}</div>
                </Table.Td>
              )}
            </Table.Tr>
            <Table.Tr style={{ width: '100%', display: 'flex' }}>
              <Table.Td style={{ width: '100%' }} colSpan={15}>
                <div style={{ display: 'flex', width: '100%' }}>
                  {c.qualificationsMap && (
                    <div style={{ flex: '0 1 auto' }}>
                      {Object.keys(c.qualificationsMap)
                        .filter((key) => {
                          return c.qualificationsMap[key]?.value === 'yes';
                        })
                        .map((kw) => (
                          <>
                            <span
                              style={{
                                marginRight: '10px',
                                fontWeight: '500',
                              }}
                            >
                              {capitalizeWords(kw)}
                            </span>
                          </>
                        ))}
                      <br />

                      <span>Missing: </span>
                      {Object.keys(c.qualificationsMap)
                        .filter((key) => {
                          return c.qualificationsMap[key]?.value === 'unknown';
                        })
                        .map((kw) => (
                          <>
                            <span
                              style={{
                                marginRight: '10px',
                                // fontWeight: 'bold',
                                color: 'rgb(154 15 15)',
                              }}
                            >
                              {capitalizeWords(kw)}
                            </span>
                          </>
                        ))}
                    </div>
                  )}
                  {c.lastViewedDate && (
                    <div
                      style={{
                        flex: '1 0 auto',
                        textAlign: 'right',
                        color: 'gray',
                        alignSelf: 'end',
                      }}
                    >
                      <span>{'Viewed '}</span>
                      <span>{daysAgo(c.lastViewedDate)}</span>
                    </div>
                  )}
                </div>
              </Table.Td>
            </Table.Tr>
          </div>
        </React.Fragment>
      );
    },
    [focusedCandidateId, updateCandidateInfo]
  );

  const renderLoadedText = useCallback(() => {
    return (
      <div style={{ display: 'flex' }}>
        {isInternal && searchingInternal ? (
          <h4>Searching Internal DB</h4>
        ) : isMonster && downloadingMonster ? (
          <h4>Downloading Monster Details</h4>
        ) : isMonster && searchingMonster ? (
          <h4>Searching Monster</h4>
        ) : (
          <h4>
            {isMonster ? monsterCandidates.length : candidates.length}{' '}
            Candidates Retrieved
            {isInternal && candidates.length > 0 && (
              <span style={{ color: 'grey' }}>
                {' ('}
                Showing {(currentInternalPage - 1) * maxCandidates + 1} -{' '}
                {currentInternalPage * maxCandidates}
                {')'}
              </span>
            )}
          </h4>
        )}

        <div
          style={{
            alignContent: 'center',
            marginLeft: '10px',
          }}
        >
          {((isInternal && searchingInternal) ||
            (isMonster && (searchingMonster || downloadingMonster))) && (
            <Loader size={20} style={{ paddingTop: '2px' }} />
          )}
        </div>
      </div>
    );
  }, [
    isInternal,
    searchingInternal,
    isMonster,
    searchingMonster,
    candidates.length,
    monsterCandidates.length,
    downloadingMonster,
    currentInternalPage,
    maxCandidates,
  ]);

  return (
    <div className='page-container-common'>
      <SourcingModal
        jobDescriptionModalOpen={jobDescriptionModalOpen}
        setJobDescriptionModalOpen={setJobDescriptionModalOpen}
        getJobSourceParameters={getJobSourceParameters}
        modalPage={modalPage}
        analyzing={analyzing}
        jobSummary={jobSummary}
        setJobSummary={setJobSummary}
        fetchCandidates={() => fetchCandidates(false)}
      />

      <div className='top-row'>
        <div className='center-buttons'>
          <Title order={2} style={{ marginBottom: '0px', marginRight: '10px' }}>
            Sourcing
          </Title>

          <Button
            size='sm'
            className='top-button job-button'
            onClick={() => setJobDescriptionModalOpen(true)}
            disabled={candidateIdFilter ? true : false}
            opacity={candidateIdFilter ? 0.5 : 1.0}
          >
            Job <IconEdit size={20} style={{ marginLeft: '6px' }} />
          </Button>

          <div style={{ width: '150px' }}>
            <Select
              allowDeselect={false}
              data={[
                SearchDatabaseOptions.internal,
                SearchDatabaseOptions.monster,
              ]}
              value={searchDB}
              onChange={(val) => setSearchDB(val as SearchDatabaseOption)}
            />
          </div>

          {searchDB === SearchDatabaseOptions.monster && (
            <div style={{ width: '250px' }}>
              <Switch
                label='Include already purchased'
                onChange={(event) =>
                  setIncludeDownloaded(event.currentTarget.checked)
                }
                checked={includeDownloaded}
              />
            </div>
          )}

          <div style={{ width: 'auto', position: 'relative' }}>
            <SourcingLocationFilter
              locationFilterState={{
                onChangeLocation: (location) => {
                  setJobSummary((prevSummary) => ({
                    ...prevSummary,
                    location: location,
                  }));
                },
                radius: jobSummary.radius,
                onChangeRadius: (radius) => {
                  setJobSummary((prevSummary) => ({
                    ...prevSummary,
                    radius: radius,
                  }));
                },
                selectedLocation: jobSummary.location,
              }}
              onClear={() => {}}
              onClose={() => {}}
              pill={true}
              disabled={isInternal && candidateIdFilter ? true : false}
            />
          </div>
          {isInternal && (
            <div>
              <SourcingDateFilter
                onClear={() => {
                  setLastSeenStartDate(null);
                  setLastSeenEndDate(null);
                }}
                dateFilterState={{
                  selectionMode: lastSeenSelectionMode,
                  startDate: lastSeenStartDate,
                  endDate: lastSeenEndDate,
                  onChangeSelectionMode: setLastSeenSelectionMode,
                  onChangeStartDate: setLastSeenStartDate,
                  onChangeEndDate: setLastSeenEndDate,
                }}
                disabled={isInternal && candidateIdFilter ? true : false}
              />
            </div>
          )}

          {searchDB === SearchDatabaseOptions.internal && (
            <>
              <div style={{ width: 'auto', position: 'relative' }}>
                <SourcingNameFilter
                  selectedCandidateName={candidateIdFilter}
                  onSelectCandidateName={(n: string | undefined) => {
                    setCandidateIdFilter(n ?? '');
                    fetchCandidates(false, { identifier: n ?? '' });
                  }}
                  onChangeCandidateName={(n: string | undefined) => {
                    setCandidateIdFilter(n ?? '');
                  }}
                  onClear={() => {
                    setCandidateIdFilter('');
                  }}
                  onClose={() => {}}
                />
              </div>
            </>
          )}
        </div>

        <div style={{ marginLeft: 'auto', display: 'flex' }}></div>
      </div>

      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ width: '50%' }}>
          <div
            style={{
              display: 'flex',
              // justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {renderLoadedText()}

            <Button
              style={{ marginLeft: 'auto' }}
              variant='light'
              size='sm'
              onClick={() => fetchCandidates(false)}
              disabled={
                searchingMonster ||
                searchingInternal ||
                jobSummary.idealCandidateSummary?.length < 10 ||
                jobSummary?.skills?.length === 0
              }
            >
              Reload
            </Button>
            {renderDownloadButton()}
          </div>
          <div
            style={{
              overflowY: 'scroll',
              maxHeight: 'calc(100vh - 240px)',
            }}
          >
            {searchDB === SearchDatabaseOptions.internal ? (
              <>
                <Table style={{ tableLayout: 'fixed', width: '100%' }}>
                  <Table.Tbody>
                    {candidates
                      .slice(
                        (currentInternalPage - 1) * maxCandidates,
                        currentInternalPage * maxCandidates
                      )
                      .map((c, index) =>
                        renderInternalCandidateRow(
                          c,
                          index,
                          c.id,
                          () => selectCandidate(c.candidateId),
                          c.checked
                        )
                      )}
                  </Table.Tbody>
                </Table>
                {candidates?.length > 0 && (
                  <Pagination
                    total={Math.floor(candidates.length / maxCandidates) + 1}
                    value={currentInternalPage}
                    onChange={handleCurrentInternalPageChange}
                  />
                )}
              </>
            ) : (
              <Table>
                <Table.Tbody>
                  {monsterCandidates
                    // candidate_info existing indicates already downloaded
                    .filter((c) => includeDownloaded || !c.alreadyDownloaded)
                    .map((c, index) => {
                      if (c.candidate_info) {
                        return renderInternalCandidateRow(
                          c.candidate_info,
                          index,
                          c.id,
                          () =>
                            selectMonsterCandidate(c.identity.md5EmailAddress),
                          c.checked
                        );
                      }
                      return (
                        <React.Fragment key={index}>
                          <div
                            className={`selectable-row ${focusedCandidateId === c.id ? 'selected' : ''}`}
                            onClick={() => {
                              setFocusedCandidateId(c.id);
                            }}
                          >
                            <Table.Tr
                              style={{
                                display: 'table',
                                width: '100%',
                                tableLayout: 'fixed',
                                borderBottom: 'none',
                              }}
                            >
                              <Table.Td width={'6%'}>
                                <Checkbox
                                  key={index}
                                  checked={c.checked}
                                  onChange={() =>
                                    selectMonsterCandidate(
                                      c.identity.md5EmailAddress
                                    )
                                  }
                                  style={{
                                    position: 'relative',
                                    top: '5px',
                                    left: '1px',
                                  }}
                                />
                              </Table.Td>
                              <Table.Td
                                width={'20%'}
                                style={{ fontWeight: 'bold' }}
                              >
                                {c.identity.name}
                              </Table.Td>
                              <Table.Td width={'23%'}>
                                {c.location.city} {c.location.state} <br />
                                <div style={{ color: 'gray' }}>
                                  {c?.location?.milesFromJob?.toFixed(0)}{' '}
                                  {' miles away'}
                                </div>
                              </Table.Td>
                              <Table.Td width={'30%'}>
                                <div>
                                  Updated:{' '}
                                  {dateStringToMY(
                                    c.identity.resumeModifiedDate
                                  )}
                                </div>
                              </Table.Td>
                              <Table.Td width={'10%'}>
                                <div style={{ marginLeft: 'auto' }}>
                                  {c.candidate_info ? (
                                    'Already Downloaded'
                                  ) : (
                                    <Button
                                      size='xs'
                                      variant='light'
                                      disabled={downloadingMonster}
                                      onClick={() =>
                                        downloadMonsterResumes([
                                          {
                                            md5EmailAddress:
                                              c.identity.md5EmailAddress,
                                            textResumeID:
                                              c.identity.textResumeID,
                                          },
                                        ])
                                      }
                                    >
                                      <IconDownload size={20} />
                                    </Button>
                                  )}
                                </div>
                              </Table.Td>
                            </Table.Tr>
                            <Table.Tr
                              style={{
                                display: 'table',
                                width: '100%',
                                tableLayout: 'fixed',
                              }}
                            >
                              <Table.Td
                                width={'52%'}
                                style={{ paddingLeft: '25px' }}
                              >
                                <div style={{ fontWeight: 'bold' }}>
                                  {c.relevance?.experience?.title?.name}{' '}
                                </div>
                                {c.relevance?.experience?.company?.name}
                                <br />
                                <div style={{ color: 'grey' }}>
                                  {dateStringToMY(
                                    c.relevance?.experience?.start
                                  )}
                                </div>
                              </Table.Td>
                              <Table.Td width={'48%'}>
                                <div>
                                  {c.relevance?.skills?.map(
                                    (s) => removeParentheses(s.matched) + ' '
                                  )}
                                </div>
                              </Table.Td>
                            </Table.Tr>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  {monsterCandidates?.length > 0 && (
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        onClick={() => fetchMonsterCandidates(true)}
                        size='sm'
                        className='top-button download-button'
                        disabled={searchingMonster}
                        style={{
                          opacity: searchingMonster ? 0.5 : 1,
                        }}
                      >
                        Load More
                      </Button>
                    </div>
                  )}
                </Table.Tbody>
              </Table>
            )}
          </div>
        </div>
        <div style={{ width: '2.5%' }}>{/* padding */}</div>
        <div style={{ width: '47%', maxHeight: '80vh' }}>
          {renderFocusedCandidate()}
        </div>
      </div>
    </div>
  );
};

export default SourcingPage;
