const TEST_KEY = 'test_web_call_key';
// we just use this 'decryption' for test calls so encryption does matter, it's just to mimic the actual link experience
export function xorEncryptDecrypt(data: string, key: string): string {
  const encoded: number[] = [];
  for (let i = 0; i < data.length; i++) {
    encoded.push(data.charCodeAt(i) ^ key.charCodeAt(i % key.length));
  }
  return String.fromCharCode(...encoded);
}

function toUrlSafeBase64(base64: string): string {
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

function fromUrlSafeBase64(urlSafeBase64: string): string {
  let base64 = urlSafeBase64.replace(/-/g, '+').replace(/_/g, '/');
  while (base64.length % 4) {
    base64 += '=';
  }
  return base64;
}

// Encode data function with URL-safe Base64
export function encodeData(data: any): string {
  const jsonString = JSON.stringify(data); // Convert data to JSON
  const encrypted = xorEncryptDecrypt(jsonString, TEST_KEY); // Encrypt with XOR
  const base64 = btoa(encrypted); // Encode to Base64
  return toUrlSafeBase64(base64); // Convert to URL-safe Base64
}

// Decode data function with URL-safe Base64
export function decodeData(encodedData: string): any {
  const base64 = fromUrlSafeBase64(encodedData); // Convert from URL-safe Base64
  const decoded = atob(base64); // Decode from Base64
  const decrypted = xorEncryptDecrypt(decoded, TEST_KEY); // Decrypt with XOR
  return JSON.parse(decrypted); // Parse back to JSON
}
