import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'api/axiosConfig';
import env from 'env';
import { Button, Loader } from '@mantine/core';
import { notifications } from '@mantine/notifications';

type Provider = 'google' | 'microsoft';

interface ProviderConfig {
  src: string;
  alt: string;
  style: {
    width: string;
    height: string;
    position: 'relative';
    right: string;
  };
}

interface OAuthResponse {
  newDomain: Array<{
    org_oauth_client_id: string;
    provider: 'GOOGLE' | 'MICROSOFT';
    refresh_token: string | null;
    oauth_client_id: string;
    org_id: string;
    access_token: string | null;
    expiry: number | null;
  }>;
}

const PROVIDER_LOGOS: Record<Provider, ProviderConfig> = {
  google: {
    src: 'imgs/google_logo.png',
    alt: 'Google Logo',
    style: {
      width: '82px',
      height: '78px',
      position: 'relative',
      right: '5px',
    },
  },
  microsoft: {
    src: 'imgs/microsoft_logo.png',
    alt: 'Outlook Logo',
    style: {
      width: '82px',
      height: '78px',
      position: 'relative',
      right: '5px',
    },
  },
};

const showNotification = (
  title: string,
  message: string,
  color: 'green' | 'red'
) => {
  notifications.show({
    title,
    message,
    color,
  });
};

const OAuthRedirect: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [provider, setProvider] = useState<Provider | null>(null);
  const [isProcessing, setIsProcessing] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const handleAuthResponse = useCallback(
    async (
      endpoint: string,
      data: Record<string, string>,
      providerName: Provider
    ): Promise<void> => {
      try {
        setProvider(providerName);
        const response = await axios.post<OAuthResponse>(
          `${env.REACT_APP_SERVER_URL}${endpoint}`,
          data
        );

        // Check if the response contains newDomain array and the provider matches
        const providerDomain = Array.isArray(response.data.newDomain)
          ? response.data.newDomain.find(
              (domain) => domain.provider === providerName.toUpperCase()
            )
          : response.data.newDomain?.provider === providerName.toUpperCase()
            ? response.data.newDomain
            : null;

        if (providerDomain?.oauth_client_id) {
          showNotification(
            'Success',
            `${providerName.charAt(0).toUpperCase() + providerName.slice(1)} account linked successfully`,
            'green'
          );
        } else {
          throw new Error('Invalid response format from server');
        }
      } catch (error) {
        const errorMessage =
          error instanceof Error
            ? error.message
            : 'An unexpected error occurred';
        console.error(`Error during ${providerName} token exchange:`, error);
        setError(`Failed to link ${providerName} account: ${errorMessage}`);
        showNotification(
          'Error',
          `Failed to link ${providerName} account. Please try again.`,
          'red'
        );
      } finally {
        setIsProcessing(false);
      }
    },
    [] // Add necessary dependencies here. NOTE: if you add more dependencies, need to make sure we don't over-call this method below
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const processAuth = async () => {
      console.log('processing auth...');
      try {
        // Microsoft OAuth flow
        const tenantId = queryParams.get('tenant');
        const adminConsent = queryParams.get('admin_consent');

        if (tenantId && adminConsent === 'True') {
          await handleAuthResponse(
            '/add_microsoft_email_tenant',
            { tenantId },
            'microsoft'
          );
          return;
        }

        // Google OAuth flow
        const googleCode = queryParams.get('code');
        const googleScope = queryParams.get('scope');

        if (googleCode && googleScope) {
          await handleAuthResponse(
            '/add_google_email_tenant',
            { code: googleCode, scope: googleScope },
            'google'
          );
          return;
        }

        setError('No valid authorization parameters found');
        setIsProcessing(false);
      } catch (error) {
        console.error('Error during authentication:', error);
        setError('Authentication process failed');
        setIsProcessing(false);
      }
    };

    processAuth();
  }, [location, handleAuthResponse]);

  const getProviderLogo = () => {
    if (!provider) return null;
    const logoConfig = PROVIDER_LOGOS[provider];
    return (
      <img src={logoConfig.src} alt={logoConfig.alt} style={logoConfig.style} />
    );
  };

  const handleReturn = () => {
    navigate('/settings?tab=email');
  };

  if (isProcessing) {
    return (
      <div className='page-container-common'>
        <div className='full-screen-message'>
          <Loader size='xl' />
          <h2>Linking your {provider || 'email'} account...</h2>
        </div>
      </div>
    );
  }

  return (
    <div className='page-container-common'>
      <div className='full-screen-message'>
        {getProviderLogo()}
        <h2>
          {error ? 'Authentication Failed' : 'Account linked successfully!'}
        </h2>
        {error && <p className='error-message'>{error}</p>}
        <Button variant='outline' onClick={handleReturn}>
          Return to Email Settings
        </Button>
      </div>
    </div>
  );
};

export default OAuthRedirect;
