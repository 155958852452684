const deployTarget = process.env.REACT_APP_DEPLOY_TARGET || 'deployed';
const reactAppEnv = process.env.REACT_APP_ENV || 'production';

// Explanation:
// By default react apps process.env always use .env.*.local if it exists if run with NODE_ENV=development,
//   before falling back to the either .env.development or .env.production files.
// The intended use caes is to always run development locally (NODE_ENV=development always when you run yarn start)
//   and production (NODE_ENV=production always when you run yarn build) in deployment

// We want a more flexible way to manage env variables, so we're going to re-implement this ourselves with more optionality and explicit behavior
// Furthermore, we want to be able to have a local .env file that is not gitignored, because:
//   1. development will just be easier if things aren't gitignored
//   2. we geniuinely want different variables things in local (like the stytch redirect urls and option to connct to local servers)
//   3. we can just set them in the codebase and not have to worry about the local ones
// because of this, we don't make .env.development.local or .env.develpment due to the default behaviors causing potential issues and don't use process.env

// TL;DR: Don't use process.env ANYWHERE in the codebase. use env instead

// This breaks standards because env is in /src, but for now that is what we are doing. In front end, nothing sensitive is in .env anyways

const config = {
  local: {
    development: require('./env/development-local.js'),
    production: require('./env/production-local'),
  },
  deployed: {
    development: require('./env/development-deployed'),
    production: require('./env/production-deployed'),
  },
}[deployTarget][reactAppEnv];

console.log('config', config);

const env = {
  ...config.env,
  IS_LOCAL: deployTarget === 'local', // for convenience
  ENV: reactAppEnv,
};

console.log('env', env);

async function fetchWithRetry(backendHostname, retries, delay) {
  let attempts = 0;

  async function attemptFetch() {
    try {
      const response = await fetch(`https://${backendHostname}/ping`);
      if (response.ok) {
        console.log(
          '[Render PR Config] Using backend hostname for PR:',
          backendHostname
        );
        env.REACT_APP_SERVER_URL = `https://${backendHostname}`;
        env.REACT_APP_WS_URL = `wss://${backendHostname}`;
        return;
      } else {
        throw new Error('Fetch failed');
      }
    } catch (error) {
      if (attempts < retries) {
        attempts++;
        console.log(`Retrying... Attempt ${attempts}`);
        await new Promise((resolve) => setTimeout(resolve, delay));
        return attemptFetch();
      } else {
        console.log(
          '[Render PR Config] Using default backend hostname in this PR'
        );
      }
    }
  }

  await attemptFetch();
}

// Render specific configuration for pull requests
async function configureEnv() {
  if (
    process.env.REACT_APP_IS_PULL_REQUEST === 'true' &&
    process.env.REACT_APP_ENV === 'development'
  ) {
    let frontendHostname = process.env.REACT_APP_RENDER_EXTERNAL_HOSTNAME;
    env.REACT_APP_STYTCH_REDIRECT_URL = `https://${frontendHostname}/authenticate`; // account for this in stych
    let backendHostname = frontendHostname.replace('frontend', 'backend');
    await fetchWithRetry(backendHostname, 0, 12000);
    console.log('[Render PR Config] env after PR config:', env);
  }
}

await configureEnv();

export default env;

// Example usage:
// import env from './env';
// console.log(env.REACT_APP_SERVER_URL);
