// Import AuthProvider and useAuth hook
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';

import WebCallPage from './pages/web-call/WebCallPage';

const Layout = () => {
  return (
    <div
      style={{
        // display: 'flex',
        height: '100%',
        minHeight: '100%',
        maxHeight: '100vh',
        display: 'contents',
      }}
    >
      {/* Conditionally render SideNavBar if not on certain routes */}
      <Routes>
        <Route
          path='/web-interview/:encodedId'
          element={<RouteWrapper component={WebCallPage} />}
        />
        <Route
          path='/web-interview/test-call/:encodedData'
          element={<RouteWrapper component={WebCallPage} />}
        />
      </Routes>
    </div>
  );
};

const InterviewApp = () => {
  return (
    <MantineProvider
      theme={{
        fontFamily: 'Inter, system-ui, sans-serif',
      }}
    >
      <ModalsProvider>
        <Notifications position='top-right' />
        <Router>
          <Layout />
        </Router>
      </ModalsProvider>
      <Notifications position='top-right' />
    </MantineProvider>
  );
};

const RouteWrapper = ({ component: Component }) => {
  return (
    <>
      <div
        style={{
          flexDirection: 'column',
          height: '100%',
          minHeight: '0%',
          maxHeight: '100vh',
          width: '100%',
          display: 'contents',
        }}
      >
        <Component />
      </div>
    </>
  );
};

export default InterviewApp;
