import React, { useState, useEffect } from 'react';
import { IconMicrophone, IconPhone } from '@tabler/icons-react';
import { RetellWebClient } from 'retell-client-js-sdk';

import { useIsMobile } from './useIsMobile';

const CurrentTimeDisplay: React.FC = () => {
  const getTime = () => {
    return new Date().toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
  };
  const [currentTime, setCurrentTime] = useState<string>(() => getTime());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(getTime());
    }, 15 * 1000);

    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: '10px' }}>{currentTime}</span>
      <span style={{ marginRight: '10px' }}>|</span>
      <span>Video Interview</span>
    </div>
  );
};

const RetellControlsArea: React.FC<{
  isCallReady: boolean;
  getAccessTokenSetupCall: () => void;
  mediaRecorderRef: React.RefObject<MediaRecorder | null>;
  retellWebClientRef: React.RefObject<RetellWebClient | null>;
  startClicked: boolean;
  callEnded: boolean;
  setStartClicked: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenMicTest: () => void;
  hasMicrophoneAccess: boolean | undefined;
}> = ({
  isCallReady,
  getAccessTokenSetupCall,
  mediaRecorderRef,
  retellWebClientRef,
  startClicked,
  setStartClicked,
  callEnded,
  handleOpenMicTest,
  hasMicrophoneAccess,
}) => {
  // TODO: dedup
  const isMobile = useIsMobile();
  const handleStopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === 'recording'
    ) {
      mediaRecorderRef.current.stop();
    }
  };

  const handleHangUp = () => {
    if (retellWebClientRef.current) {
      retellWebClientRef.current.stopCall();
      try {
        handleStopRecording();
      } catch {
        console.warn('could not stop recording on retell event');
      }
    } else {
      console.error('RetellWebClient is not initialized.');
    }
  };

  const EndCallButton: React.FC = () => {
    return (
      <button className='phone-action-button end-button' onClick={handleHangUp}>
        {/* Tabler Hang Up Icon */}
        {'End Interview  '}
        <IconPhone
          size={20}
          stroke={1.6}
          style={{
            transform: 'rotate(135deg)',
            marginLeft: '6px',
            position: 'relative',
            top: '5px',
          }}
        />
      </button>
    );
  };

  const BeginInterviewButton: React.FC = () => {
    return (
      <button
        disabled={!isCallReady || !hasMicrophoneAccess}
        className='phone-action-button begin-button'
        onClick={() => {
          setStartClicked(true);
          getAccessTokenSetupCall();
        }}
      >
        Begin Interview
      </button>
    );
  };

  return (
    <>
      <CurrentTimeDisplay />
      <div
        style={{
          display: 'flex',
          gap: isMobile ? '16px' : '10px',
          justifyContent: 'center',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        {!startClicked && !callEnded && (
          <>
            <button
              className='phone-action-button'
              onClick={handleOpenMicTest}
              style={{
                color: 'white',
                border: 'none',
                padding: '8px 16px',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <IconMicrophone size={20} />
              Test Microphone
            </button>
            {isCallReady && <BeginInterviewButton />}
          </>
        )}
        {startClicked && !callEnded && <EndCallButton />}
      </div>
    </>
  );
};

export default RetellControlsArea;
