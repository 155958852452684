import React, { useState, useEffect } from 'react';
import './AudioPlayer.css';
import env from 'env';
import axios from 'api/axiosConfig';
import { Loader } from '@mantine/core';

const VideoPlayer = ({ callId, seekTimestamp }) => {
  const [isVideoAvailable, setIsVideoAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [videoUrl, setVideoUrl] = useState('');
  const videoRef = React.useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (seekTimestamp !== null && videoRef.current) {
      videoRef.current.currentTime = seekTimestamp;
      videoRef.current.play();
    }
  }, [seekTimestamp]);

  useEffect(() => {
    let pollingInterval;

    const checkVideoAvailability = async () => {
      try {
        const response = await axios.get(
          `${env.REACT_APP_SERVER_URL}/check_web_recording/${callId}`
        );
        if (response.status === 200 && response.data.available) {
          setIsVideoAvailable(true);
          setVideoUrl(response.data.video_url);
          setIsLoading(false);
          clearInterval(pollingInterval); // Stop polling when video is available
        }
      } catch (error) {
        console.log('Video not available yet. Retrying...');
      }
    };

    // Start polling
    pollingInterval = setInterval(checkVideoAvailability, 3000);
    checkVideoAvailability();

    // Clean up polling on component unmount
    return () => clearInterval(pollingInterval);
  }, [callId]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        position: 'relative',
      }}
    >
      {isLoading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            position: 'relative',
            width: '60%',
            flexDirection: 'column',
            borderRadius: '16px',
            border: '1px solid var(--salv-dark-2)',
            aspectRatio: 1.5,
            // height: '300px',
            opacity: '.7',
          }}
        >
          <h4 style={{ marginBottom: '4px' }}>Loading Video</h4>
          <Loader type='dots' size='sm' />
        </div>
      )}
      {isVideoAvailable && (
        <video
          controls
          ref={videoRef}
          style={{
            borderRadius: '16px',
            width: '60%',
            position: 'relative',
            border: '4px solid var(--salv-dark-1)',
          }}
        >
          <source src={videoUrl} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};

const WebCallRecordingPlayer = ({ callId, seekTimestamp }) => {
  return (
    <div style={{ display: 'flex', fontSize: '14px' }}>
      {!!callId && (
        <VideoPlayer callId={callId} seekTimestamp={seekTimestamp} />
      )}
    </div>
  );
};

export default WebCallRecordingPlayer;
