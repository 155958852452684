import { useEffect, useRef, useCallback } from 'react';
import env from 'env';

const floatTo16BitPCM = (input: Float32Array): Int16Array => {
  const output = new Int16Array(input.length);
  for (let i = 0; i < input.length; i++) {
    let s = Math.max(-1, Math.min(1, input[i]));
    output[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
  }
  return output;
};

const useAudioStream = (callId: string) => {
  const socketRef = useRef<WebSocket | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const processorRef = useRef<ScriptProcessorNode | null>(null);

  const getUserAudioStream = useCallback(async (): Promise<MediaStream> => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStreamRef.current = stream;
      return stream;
    } catch (error) {
      console.error('Error accessing microphone:', error);
      throw error;
    }
  }, []);

  const setupWebSocket = useCallback(() => {
    socketRef.current = new WebSocket(
      `${env.REACT_APP_WS_URL}/ws/web_call/audio/${callId}`
    );

    socketRef.current.onopen = () => {
      console.log('Stream user audio WebSocket connection established');
    };

    socketRef.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    socketRef.current.onclose = () => {
      console.log('WebSocket connection closed');
    };
  }, [callId]);

  const processAudioStream = useCallback(async (stream: MediaStream) => {
    const AudioContext =
      window.AudioContext || (window as any).webkitAudioContext;
    const audioContext = new AudioContext();
    audioContextRef.current = audioContext;

    const source = audioContext.createMediaStreamSource(stream);
    const processor = audioContext.createScriptProcessor(4096, 1, 1);
    processorRef.current = processor;

    source.connect(processor);
    processor.connect(audioContext.destination);

    processor.onaudioprocess = (event) => {
      const audioData = event.inputBuffer.getChannelData(0);
      const pcmData = floatTo16BitPCM(audioData);
      if (
        socketRef.current &&
        socketRef.current.readyState === WebSocket.OPEN
      ) {
        socketRef.current.send(pcmData);
      }
    };
  }, []);

  const startStreamingAudio = useCallback(async () => {
    try {
      setupWebSocket();
      const stream = await getUserAudioStream();
      await processAudioStream(stream);
    } catch (error) {
      console.error('Error starting audio stream:', error);
    }
  }, [getUserAudioStream, processAudioStream, setupWebSocket]);

  const stopStreamingAudio = useCallback(() => {
    // Stop the media stream tracks
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      mediaStreamRef.current = null;
    }

    // Close the WebSocket
    if (socketRef.current) {
      socketRef.current.close();
      socketRef.current = null;
    }

    // Stop the audio processor and close the audio context
    if (processorRef.current) {
      processorRef.current.disconnect();
      processorRef.current = null;
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }

    console.log('Audio streaming stopped');
  }, []);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      stopStreamingAudio();
    };
  }, [stopStreamingAudio]);

  return {
    startStreamingAudio,
    stopStreamingAudio,
  };
};

export default useAudioStream;
