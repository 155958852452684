export type IndeedJob = {
  jobCity: string;
  jobState: string;
  jobTitle: string;
};

export enum RequirementImportance {
  Major = 'MAJOR',
  Normal = 'NORMAL',
  Minor = 'MINOR',
  Info = 'INFO',
  GradeOnly = 'GRADE_ONLY',
}

export interface CandidateRequirement {
  question: string;
  importance: RequirementImportance;
  llmGradingInstructions?: string;
  failIfZero: boolean;
}
