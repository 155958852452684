import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Button, Flex, Input, Stack, Text, Textarea } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';

const FailedCallSmsStep = ({ scriptInfo }) => {
  const getMessagesArray = useCallback(
    (messages: string | string[]): string[] => {
      return Array.isArray(messages)
        ? messages
        : messages.split('-').filter((msg) => msg.trim() !== '');
    },
    []
  );
  const [newMessage, setNewMessage] = useState<string>('');
  const [msgEditIndex, setMsgEditIndex] = useState<number | null>(null);
  const messages = getMessagesArray(scriptInfo.values.failedCallSms);

  const handleSaveMessage = useCallback(
    (index: number, editedMessage: string) => {
      const currentMessages = getMessagesArray(scriptInfo.values.failedCallSms);
      const updatedMessages = [...currentMessages];
      updatedMessages[index] = editedMessage;
      scriptInfo.setFieldValue('failedCallSms', updatedMessages);
      setMsgEditIndex(null);
    },
    [scriptInfo, getMessagesArray]
  );

  const handleRemoveMessage = useCallback(
    (index: number) => {
      const currentMessages = getMessagesArray(scriptInfo.values.failedCallSms);
      const updatedMessages = currentMessages.filter((_, i) => i !== index);
      scriptInfo.setFieldValue('failedCallSms', updatedMessages);
    },
    [scriptInfo, getMessagesArray]
  );

  const handleAddMessage = useCallback(() => {
    if (newMessage.trim()) {
      const currentMessages = getMessagesArray(scriptInfo.values.failedCallSms);
      const updatedMessages = [...currentMessages, newMessage];
      scriptInfo.setFieldValue('failedCallSms', updatedMessages);
      setNewMessage('');
    }
  }, [newMessage, scriptInfo, getMessagesArray]);

  const handleEditMessage = useCallback((index: number) => {
    setMsgEditIndex(index);
  }, []);

  const msgTextareaRef = useRef<HTMLTextAreaElement | null>(null);
  useEffect(() => {
    if (msgTextareaRef.current && msgEditIndex !== -1) {
      msgTextareaRef.current.focus();
      const value = msgTextareaRef.current.value;
      if (value) {
        msgTextareaRef.current.setSelectionRange(value.length, value.length);
      }
    }
  }, [msgEditIndex]);

  return (
    <>
      <Text fz='sm' c='dimmed'>
        Add messages to be sent to the candidate if they don't pick up the
        phone. Use {'{candidate_name}'} to insert the candidate's name.
      </Text>
      <div
        style={{
          padding: '20px',
          border: '1px solid #8080802e',
          borderRadius: '12px',
        }}
      >
        <Stack
          mt='md'
          style={{
            maxHeight: '500px',
            minHeight: '350px',
            overflowY: 'auto',
          }}
        >
          {messages.length > 0 ? (
            messages.map((message, index) => (
              <Flex
                key={index}
                justify='space-between'
                align='center'
                className='chatBubble'
                style={{
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  marginRight: '0px',
                  marginLeft: 'auto',
                  backgroundColor: '#f0f0f0',
                  borderRadius:
                    index === messages.length - 1 ? '20px 20px 0 20px' : '20px',
                  width: msgEditIndex === index ? '70%' : 'auto',
                }}
              >
                {msgEditIndex === index ? (
                  <Textarea
                    value={message}
                    onChange={(e) => {
                      const updatedMessages = [...messages];
                      updatedMessages[index] = e.target.value;
                      scriptInfo.setFieldValue(
                        'failedCallSms',
                        updatedMessages
                      );
                    }}
                    variant='unstyled'
                    style={{
                      flexGrow: 1,
                      fontSize: '0.875rem',
                      border: '1px solid #cac9c9',
                      borderRadius: '6px',
                    }}
                    minRows={1}
                    ref={msgTextareaRef}
                  />
                ) : (
                  <Text
                    style={{ fontSize: '0.875rem' }}
                    onClick={() => handleEditMessage(index)}
                    className='editableText'
                  >
                    {message}
                  </Text>
                )}
                <Flex style={{ gap: '14px', marginLeft: '20px' }}>
                  {msgEditIndex === index ? (
                    <Button
                      onClick={() => handleSaveMessage(index, messages[index])}
                    >
                      Done
                    </Button>
                  ) : (
                    <>
                      <Button
                        variant='subtle'
                        onClick={() => handleRemoveMessage(index)}
                        style={{ padding: '0px 4px' }}
                      >
                        <IconTrash size={16} color={'red'} />
                      </Button>
                    </>
                  )}
                </Flex>
              </Flex>
            ))
          ) : (
            <Text style={{ textAlign: 'center', color: 'gray' }}>
              No messages added
            </Text>
          )}
        </Stack>
        <Flex align='center' gap='md' mt='md'>
          <Input
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder='Type your message here'
            style={{ flexGrow: 1 }}
          />
          <Button onClick={handleAddMessage}>Add Message</Button>
        </Flex>
      </div>
    </>
  );
};

export default FailedCallSmsStep;
