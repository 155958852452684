// Set these values for deployed development
export const env = {
  REACT_APP_SERVER_URL: 'https://staffing-backend.onrender.com', // https://run.salv.ai
  REACT_APP_WS_URL: 'wss://staffing-backend.onrender.com', // wss://run.salv.ai
  REACT_APP_STYTCH_PROJECT_ENV: 'test',
  REACT_APP_STYTCH_REDIRECT_URL:
    'https://staffing-frontend.onrender.com/authenticate', // https://chat.salv.ai/authenticate
  REACT_APP_STYTCH_PUBLIC_TOKEN:
    'public-token-test-95ca1437-a1fd-404c-961e-b632b4446c81',
  REACT_APP_INTERVIEW_REDIRECT_URL: 'https://dev-interview.salv.ai',
};
