import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { Link } from 'react-router-dom';
import env from 'env';
import { useStytchB2BClient, useStytchMemberSession } from '@stytch/react/b2b';
import {
  Card,
  TextInput,
  Container,
  PasswordInput,
  Center,
  Loader,
} from '@mantine/core';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

import './LoginCommon.css';
import LoginButton from './LoginButton';
import axios from '../../api/axiosConfig';

type AuthState =
  | 'NoOrg'
  | 'Invited'
  | 'CodeExpired'
  | 'PasswordReset'
  | undefined;

function hasAtLeastTwoWords(str) {
  if (!str) {
    return;
  }
  const words = str.trim().split(/\s+/); // Trim and split by one or more spaces
  return words.length >= 2;
}

const RedirectLinkHandler = () => {
  const navigate = useNavigate();
  const stytch = useStytchB2BClient();
  const [orgName, setOrgName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [loadState, setLoadState] = useState<AuthState>(undefined);
  const [similarOrgs, setSimliarOrgs] = useState<number | undefined>(undefined);
  const [invitedOrgs, setInvitedOrgs] = useState<any[]>([]);
  const [emailDomain, setEmailDomain] = useState('');
  const [interMediateSessionToken, setIntermediateSessionToken] = useState('');
  const [fullName, setFullName] = useState('');
  const [creatingOrg, setCreatingOrg] = useState(false);
  const [passwordResetToken, setPasswordResetToken] = useState('');
  const [passwordResetting, setPasswordResetting] = useState(false);
  const [orgCreateError, setOrgCreateError] = useState(false);
  const [resetPasswordError, setResetPasswordError] = useState(false);

  const { session } = useStytchMemberSession();
  console.log('session during create', session);

  const calledOnce = useRef(false);

  const navigateHome = useCallback(() => {
    navigate(`/`);
    window.location.reload();
  }, [navigate]);

  const [isValidPassword, setIsValidPassword] = useState(false);
  const strengthCheck = useCallback(async () => {
    return await stytch.passwords.strengthCheck({
      password: password,
    });
  }, [stytch, password]);

  useEffect(() => {
    const checkPassword = async () => {
      const resp = await strengthCheck();
      if (resp?.valid_password) {
        setIsValidPassword(true);
      } else {
        setIsValidPassword(false);
      }
    };

    checkPassword();
  }, [strengthCheck]);

  const pageOpenedAt = useRef(new Date().getTime());

  useEffect(() => {
    // The password reset will not work after 5 minutes due to stytch validations
    // Therefore, we close the page
    const checkAuthenticationTime = async () => {
      const currentTime = new Date().getTime();
      const timeDiffInMinutes =
        (currentTime - pageOpenedAt.current) / 1000 / 60;
      console.log('timeDiffInMinutes', timeDiffInMinutes);
      if (timeDiffInMinutes > 4.2) {
        // Exit the page, revoke the session, and redirect to another route
        await stytch.session.revoke();
        navigate('/login');
      }
    };

    checkAuthenticationTime();

    const intervalId = setInterval(checkAuthenticationTime, 20000);

    // Cleanup the interval on unmount
    return () => clearInterval(intervalId);
  }, [stytch.session, navigate]);

  const getOrgsWithEmail = useCallback(async (email: string) => {
    const COMMON_EMAIL_DOMAINS = [
      'gmail.com',
      'yahoo.com',
      'outlook.com',
      'hotmail.com',
      'icloud.com',
      'aol.com',
      'protonmail.com',
      'mail.com',
      'zoho.com',
      'yandex.com',
      'comcast.net',
      'msn.com',
      'live.com',
      'me.com',
      'att.net',
      'verizon.net',
      'gmx.com',
      'bellsouth.net',
      'cox.net',
      'earthlink.net',
    ];
    const INTERNAL_EMAIL_DOMAIN = 'salv.ai';
    if (
      COMMON_EMAIL_DOMAINS.find((e) => email.includes(e)) ||
      email.includes(INTERNAL_EMAIL_DOMAIN)
    ) {
      setSimliarOrgs(0);
      return;
    }
    try {
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/get_orgs_with_email`,
        { email: email }
      );
      console.log('existing orgs response', response);
      if (response?.data?.organizations?.length > 0) {
        setSimliarOrgs(response?.data?.organizations?.length || 0);
      }
    } catch (error) {
      alert('error getting orgs!!');
      console.error('Error getting organizations:', error);
    } finally {
    }
  }, []);

  useEffect(() => {
    const authenticateCode = debounce(async () => {
      if (calledOnce.current) {
        return;
      }
      console.log('authenticate code', calledOnce);
      calledOnce.current = true;
      try {
        // from token, authenticate.
        // this sets the stych_intermediate_session_token which can be used to authorize org creation
        const token = new URLSearchParams(window.location.search).get('token');
        const token_type = new URLSearchParams(window.location.search).get(
          'stytch_token_type'
        );

        if (token_type === 'multi_tenant_magic_links') {
          // If the page is opened from an invite accept link, log them directly into that org
          await stytch.magicLinks.authenticate({
            magic_links_token: token || '',
            session_duration_minutes: 525600,
          });
          return;
        }
        if (token_type === 'multi_tenant_passwords') {
          setPasswordResetToken(token || '');
          setLoadState('PasswordReset');
          return;
        }

        // Else, proceed with the 1 user -> org flow
        const authResponse = await stytch.magicLinks.discovery.authenticate({
          discovery_magic_links_token: token || '',
        });
        const intermediate_token = authResponse.intermediate_session_token;
        setIntermediateSessionToken(intermediate_token);

        setEmailDomain(authResponse.email_address.split('@')[1]);
        const discoveredOrganizations = authResponse.discovered_organizations;
        const memberOrgs = discoveredOrganizations.filter(
          (o) => o.membership.member?.status.toLocaleLowerCase() === 'active'
        );
        console.log('member orgs', memberOrgs);
        if (memberOrgs?.length > 0) {
          const org_id = memberOrgs[0].organization.organization_id;
          // exchange intermediate session token for session token
          await stytch.discovery.intermediateSessions.exchange({
            organization_id: org_id,
            session_duration_minutes: 525600,
          });
        } else {
          const invitedOrgs = discoveredOrganizations.filter(
            (o) => o.membership.member?.status.toLocaleLowerCase() === 'invited'
          );
          console.log('invitedOrgs', invitedOrgs);
          setInvitedOrgs(invitedOrgs);
          console.log('getting orgs with email');
          getOrgsWithEmail(authResponse.email_address);
          console.log('setting load state');
          setLoadState('NoOrg');
        }
        console.log('done');
      } catch (error) {
        console.error('Error fetching organizations using discovery:', error);
        // Note, don't need to do error, can switch based on type of token
        setLoadState('CodeExpired');
      }
    }, 500);

    if (session) {
      console.log('session changed', session);
      navigateHome();
    } else {
      authenticateCode();
    }
  }, [stytch, session, getOrgsWithEmail, navigateHome]);

  const createOrganization = async () => {
    if (isOrgCreateDisabled) {
      setOrgCreateError(true);
      return;
    }
    setOrgCreateError(false);
    setCreatingOrg(true);
    try {
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/finish_account_and_org_creation`,
        {
          intermediate_session_token: interMediateSessionToken,
          org_name: orgName,
          full_name: fullName,
          password: password,
        }
      );
      const { stychSessionResponse } = response.data;
      console.log('stychSessionResponse', stychSessionResponse);
      const { session_token } = stychSessionResponse;
      // This should persist the session token to cookies for us
      try {
        console.log('update session to token:', session_token);
        stytch.session.updateSession({
          session_token: session_token,
        });
        console.log('authenticate');
        const authSession = await stytch.session.authenticate({
          session_duration_minutes: 525600,
        });
        console.log('authed session', authSession);
      } catch (error) {
        alert('Error updating session');
        console.error('error updating session', error);
        setCreatingOrg(false);
      }
    } catch (error) {
      alert('Error creating organization');
      console.error('Error creating org:', error);
      setCreatingOrg(false);
    } finally {
    }
  };

  const renderLoading = () => {
    return (
      <div style={{ alignItems: 'center' }}>
        <h3>Authenticating</h3>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Loader />
        </div>
      </div>
    );
  };

  const renderWarning = () => {
    if (invitedOrgs.length > 0)
      return (
        <p>
          Warning: You've already been invited to an organization. Check your
          email to accept.
        </p>
      );
    const numOrgs = similarOrgs || 0;
    if (similarOrgs)
      return (
        <p>
          Warning: There {numOrgs > 1 ? 'are' : 'is'} already ({numOrgs})
          organization{numOrgs > 1 ? 's' : ''} under the domain {emailDomain}.{' '}
          <br />
          If your team already has an account, ask your admin for an invitation.
        </p>
      );
    return <></>;
  };

  const orgNameInvalid = orgName?.length < 3;
  const nameInvalid = !hasAtLeastTwoWords(fullName);

  const isOrgCreateDisabled = useMemo(() => {
    return (
      orgNameInvalid ||
      nameInvalid ||
      password !== confirmedPassword ||
      !isValidPassword
    );
  }, [
    password,
    confirmedPassword,
    nameInvalid,
    isValidPassword,
    orgNameInvalid,
  ]);

  const renderCreateOrg = () => {
    // Note: unlikely edge case but still render create if they're in an org
    return (
      <>
        <h2 style={{ marginTop: '5px' }}>Create an Organization</h2>
        {renderWarning()}
        {!similarOrgs && (
          <>
            <TextInput
              label='Full Name'
              placeholder='Enter your first and last name'
              required
              type='email'
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              style={{ width: '100%' }}
              mb='md'
              size='md'
              error={
                orgCreateError && nameInvalid && 'Enter a first and last name'
              }
            />
            <PasswordInput
              label='Password'
              placeholder='Enter your password'
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ width: '100%' }}
              mb='md'
              size='md'
              error={
                orgCreateError &&
                !isValidPassword &&
                'Password must be at least 8 characters'
              }
            />
            <PasswordInput
              label='Confirm Password'
              placeholder='Re-enter your password'
              required
              value={confirmedPassword}
              onChange={(e) => setConfirmedPassword(e.target.value)}
              style={{ width: '100%' }}
              mb='md'
              size='md'
              error={
                orgCreateError &&
                confirmedPassword !== password &&
                'Passwords not equal'
              }
            />
            <TextInput
              label='Organization Name'
              placeholder='Enter organization name'
              required
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
              style={{ width: '100%' }}
              mb='md'
              size='md'
              error={orgCreateError && orgNameInvalid}
            />
            <br />
            <LoginButton disabled={creatingOrg} onClick={createOrganization}>
              Create
            </LoginButton>
          </>
        )}
      </>
    );
  };

  const passwordResetDisabled =
    !isValidPassword || confirmedPassword !== password;

  const resetPassword = useCallback(async () => {
    if (passwordResetDisabled) {
      setResetPasswordError(true);
      return;
    }
    setResetPasswordError(false);
    setPasswordResetting(true);
    try {
      await stytch.passwords.resetByEmail({
        password_reset_token: passwordResetToken,
        password: password,
        session_duration_minutes: 525600,
      });
    } catch (error) {
      alert('An error occured');
    } finally {
      setPasswordResetting(false);
    }
  }, [stytch, passwordResetToken, password, passwordResetDisabled]);

  const renderPasswordReset = () => {
    // Note: unlikely edge case but still render create if they're in an org
    return (
      <div>
        <h2 style={{ marginTop: '5px' }}>Reset your password</h2>
        {
          <>
            <PasswordInput
              label='Password'
              placeholder='Enter your password'
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              mb='md'
              error={
                resetPasswordError &&
                !isValidPassword &&
                'Password must be at least 8 characters'
              }
            />
            <PasswordInput
              label='Confirm Password'
              placeholder='Re-enter your password'
              required
              value={confirmedPassword}
              onChange={(e) => setConfirmedPassword(e.target.value)}
              mb='md'
              error={resetPasswordError && password !== confirmedPassword}
            />
            <br />
            <LoginButton disabled={passwordResetting} onClick={resetPassword}>
              Reset Password
            </LoginButton>
          </>
        }
      </div>
    );
  };

  const renderError = () => {
    return (
      <div>
        <p>Error: Authentication Link Expired</p>
        <Link to='/login' style={{ textDecoration: 'none' }}>
          <LoginButton onClick={() => {}} disabled={false}>
            Generate New Link
          </LoginButton>
        </Link>
      </div>
    );
  };

  const renderCard = () => {
    switch (loadState) {
      case undefined:
        return renderLoading();
      case 'CodeExpired':
        return renderError();
      case 'NoOrg':
        return renderCreateOrg();
      case 'PasswordReset':
        return renderPasswordReset();
      default:
        return renderLoading();
    }
  };

  return (
    <div className='background-image'>
      <Container style={{ height: '100vh' }}>
        <Center style={{ height: '90%' }}>
          <Card className='login-card'>{renderCard()}</Card>
        </Center>
      </Container>
    </div>
  );
};

export default RedirectLinkHandler;
