import React from 'react';
import { Stack, Text, Textarea, TextInput, Title } from '@mantine/core';

const FailedCallEmailStep = ({ scriptInfo, recruitingEmail }) => {
  return (
    <>
      <Stack h='100%'>
        <Title order={5}>Email for Failed Calls</Title>
        <Text fz='sm' c='dimmed'>
          This is the email that will be sent to the candidate if they don't
          pick up the phone. Use {'{candidate_name}'} to insert the candidate's
          name and {'{recruiter_phone_number}'} to include the recruiter's
          callback number.
        </Text>
        {!recruitingEmail && (
          <div className='callout callout-md'>
            Your organization admin must set up a recruiting email to utilize
            this feature.
          </div>
        )}
        <TextInput
          disabled={!recruitingEmail}
          {...scriptInfo.getInputProps('failedCallEmailSubject')}
          label='Subject'
        />
        <Textarea
          className='fullHeightTextarea'
          {...scriptInfo.getInputProps('failedCallEmailBody')}
          disabled={!recruitingEmail}
          label='Body'
        />
        <br />
      </Stack>
    </>
  );
};

export default FailedCallEmailStep;
