import React from 'react';
import {
  Group,
  NumberInput,
  SimpleGrid,
  Stack,
  Switch,
  TextInput,
  Select,
} from '@mantine/core';

import IndeedJobsField from './IndeedJobsField';

const CallerSettingsStep = ({ campaignSettings, campaignId }) => {
  const email = localStorage.getItem('email') || '';
  const orgId = localStorage.getItem('orgId') || '';
  const isInternal = email.includes('salv.ai');
  const isWebCall = campaignSettings.values.webCall;

  const canSeeWebCall =
    email?.includes('john@salv.ai') ||
    email?.includes('mason@salv.ai') ||
    email?.includes('mswoff19@gmail.com') ||
    email?.includes('michael@salv.ai') ||
    orgId?.includes('537cc850-0be1-418c-a94e-12e5f1aa083d');

  return (
    <Stack justify='space-between' h='100%'>
      <>
        <SimpleGrid cols={1} style={{ width: '80%', minWidth: '400px' }}>
          <Stack>
            <TextInput
              label='Email for campaign results'
              placeholder='Your email'
              required
              {...campaignSettings.getInputProps('email')}
            />
            {isInternal && (
              <div>
                <IndeedJobsField
                  campaignId={campaignId}
                  campaignSettings={campaignSettings}
                />
              </div>
            )}
            {isInternal && !isWebCall && (
              <>
                <TextInput
                  label='Call From Number:'
                  {...campaignSettings.getInputProps('callFromNumber')}
                />
              </>
            )}
            <Select
              label='Voice'
              data={[
                'Lisa (American)',
                'George (American)',
                'Abby (British)',
                'William (British)',
              ]}
              placeholder='Select a voice'
              nothingFoundMessage='No matching voices'
              {...campaignSettings.getInputProps('voiceName')}
              allowDeselect={false}
            />
            {!isWebCall && (
              <Group
                style={{
                  border: '1px solid #80808066',
                  padding: '16px',
                  borderRadius: '12px',
                  marginTop: '20px',
                }}
              >
                <Switch
                  label='Auto-reschedule on voicemail'
                  checked={campaignSettings.values.rescheduleCallOnVoicemail}
                  {...campaignSettings.getInputProps(
                    'rescheduleCallOnVoicemail'
                  )}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    gap: '20px',
                  }}
                >
                  <NumberInput
                    w={80}
                    label='# of retries'
                    disabled={
                      !campaignSettings.values.rescheduleCallOnVoicemail
                    }
                    min={1}
                    max={5}
                    allowDecimal={false}
                    {...campaignSettings.getInputProps('callRetryCount')}
                  />
                  <NumberInput
                    w={100}
                    label='Call again after'
                    disabled={
                      !campaignSettings.values.rescheduleCallOnVoicemail
                    }
                    allowNegative={false}
                    rightSection='hours'
                    rightSectionWidth='max-content'
                    {...campaignSettings.getInputProps('callRetryHours')}
                  />
                </div>
              </Group>
            )}
            {isWebCall && (
              <NumberInput
                w={200}
                label='Interview Link Expiration'
                allowNegative={false}
                rightSection='days'
                rightSectionWidth='max-content'
                {...campaignSettings.getInputProps('webLinkExpirationDays')}
              />
            )}
            {isWebCall && (
              <div>
                <h5
                  style={{
                    marginTop: '20px',
                    marginBottom: '0px',
                    color: 'var(--salv-dark-9)',
                  }}
                >
                  Reminder Emails
                </h5>
                <Group
                  style={{
                    border: '1px solid #80808066',
                    padding: '16px',
                    borderRadius: '12px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      gap: '20px',
                    }}
                  >
                    <NumberInput
                      w={180}
                      label='# Reminder Emails'
                      min={1}
                      max={5}
                      allowDecimal={false}
                      {...campaignSettings.getInputProps('reminderEmailCount')}
                    />
                    <NumberInput
                      w={180}
                      label='Send Reminder Every'
                      allowNegative={false}
                      rightSection='hours'
                      rightSectionWidth='max-content'
                      {...campaignSettings.getInputProps('reminderEmailHours')}
                    />
                  </div>
                </Group>
              </div>
            )}
            {canSeeWebCall && (
              <>
                <h5
                  style={{
                    marginTop: '10px',
                    marginBottom: '0px',
                    color: 'var(--salv-dark-9)',
                  }}
                >
                  Change Call Type
                </h5>
                <Switch
                  label='Web Call'
                  checked={campaignSettings.values.webCall}
                  {...campaignSettings.getInputProps('webCall')}
                />
              </>
            )}
            <div style={{ marginBottom: '6px' }}></div>
            <div className='scroll-fade-bottom'></div>
          </Stack>
        </SimpleGrid>
      </>
    </Stack>
  );
};

export default CallerSettingsStep;
