import React, { Fragment } from 'react';
import { Stack, Text, Title } from '@mantine/core';

import { getOutroFromSchedule } from './Outro';

const ScriptPreviewStep = ({ scriptInfo, requirements }) => {
  function renderWithWaitLines(text) {
    const lines = text.split(/\n+/);
    return lines.map((line, index) => (
      <Fragment key={index}>
        <Text
          size='sm'
          style={{
            wordWrap: 'break-word', // Break long words
            wordBreak: 'break-all', // Break all lines
            whiteSpace: 'pre-wrap', // Allow text to wrap
          }}
        >
          {line}
        </Text>
        {
          <Text size='xs' c='gray' className='wait-for-response'>
            Wait for response
          </Text>
        }
      </Fragment>
    ));
  }

  return (
    <>
      <Stack>
        <Title order={6}>Intro</Title>
        <Text
          size='sm'
          style={{
            wordWrap: 'break-word' /* Break long words */,
            wordBreak: 'break-all' /* Break all lines */,
            whiteSpace: 'pre-wrap' /* Allow text to wrap */,
          }}
        >
          {renderWithWaitLines(scriptInfo.values.callIntro)}
        </Text>
        <Title order={6}>Screening Questions</Title>
        <div>
          {requirements?.map((requirement, i) => (
            <Fragment key={i}>
              <Text size='sm'>{requirement.question}</Text>
              {
                <Text size='xs' c='gray' className='wait-for-response'>
                  Wait for response
                </Text>
              }
            </Fragment>
          ))}
        </div>
        <Title order={6}>Outro</Title>
        <Text
          size='sm'
          style={{
            wordWrap: 'break-word' /* Break long words */,
            wordBreak: 'break-all' /* Break all lines */,
            whiteSpace: 'pre-wrap' /* Allow text to wrap */,
          }}
        >
          {
            getOutroFromSchedule(scriptInfo.values.scheduleFollowUp)
              .previewDisplay
          }
        </Text>
      </Stack>
    </>
  );
};

export default ScriptPreviewStep;
